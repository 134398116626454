export class ImageInfo {
  /**@type {string} */
  id;
  /**@type {string} */
  imagePath;
  /**@type {string} */
  thumbPath;

  /**
   * 
   * @param {string} id 
   * @param {string} imagePath 
   * @param {string} thumbPath 
   */
  constructor(id, imagePath, thumbPath) {
    this.id = id;
    this.imagePath = imagePath;
    this.thumbPath = thumbPath;
  }

  /**
   * @param {any} json
   * @returns {ImageInfo|null} 
   */
  static ParseJson(json) {
    return json ? new ImageInfo(json.id, json.imagePath, json.thumbPath) : null;
  }
}

export class Pagination {
  /**@type {number} */
  page;
  /**@type {number} */
  perPage;
  /**@type {number} */
  totalPages;
  /**@type {number} */
  totalItems;

  /**
   * 
   * @param {number} page 
   * @param {number} perPage 
   * @param {number} totalPages 
   * @param {number} totalItems 
   */
  constructor(page, perPage, totalPages, totalItems) {
    this.page = page;
    this.perPage = perPage;
    this.totalPages = totalPages;
    this.totalItems = totalItems;
  }

  /**
   * @param {any} json
   * @returns {Pagination|null} 
   */
  static ParseJson(json) {
    return json ? new Pagination(json.page, json.perPage, json.totalPages, json.totalItems) : null;
  }
}