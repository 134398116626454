import { Pagination } from "../commonStucts";
import HttpClient, { BaseResult } from "../httpClient";

export class ColorInfo {
  /**@type {string} */
  id;
  /**@type {string} */
  name;

  /**
   * @param {string} id 
   * @param {string} name 
   */
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  /**
   * @param {any} json
   * @returns {ColorInfo|null} 
   */
  static ParseJson(json) {
    return json ? new ColorInfo(json.id, json.name) : null;
  }
}

export class ColorPagedList {
  /**@type {ColorInfo[]} */
  items;
  /**@type {Pagination} */
  pagination;

  /**
   * @param {ColorInfo[]} items 
   * @param {Pagination} pagination 
   */
  constructor(items, pagination) {
    this.items = items;
    this.pagination = pagination;
  }

  /**
   * @param {any} json
   * @returns {ColorPagedList|null} 
   */
  static ParseJson(json) {
    if (json) {
      const colors = json.items ? json.items.map(v => ColorInfo.ParseJson(v)) : [];
      const pagination = Pagination.ParseJson(json.pagination);
      return new ColorPagedList(colors, pagination);
    }
    return null;
  }
}

export class CreateOrEditColorResult extends BaseResult {
  /**@type {ColorInfo} */
  color;

  constructor(success, errorMessage, color) {
    super(success, errorMessage);
    this.color = color;
  }
}

export class Colors {
  /**
 * @param {number} page № страницы
 * @param {number} perPage Кол-во результатов на странице
 * @returns {Promise<ColorPagedList>}
 */
  async getPagedList(page, perPage) {
    const result = await HttpClient.Get(`/v1/admin/colors?page=${page}&perPage=${perPage}`);
    if (result.data) {
      return ColorPagedList.ParseJson(result.data);
    }
    return new BaseResult(false, result.error.message);
  }

  /**
 * @param {string} name 
 * @returns {Promise<CreateOrEditColorResult>}
 */
  async createColor(name) {
    const json = { name };

    const result = await HttpClient.PostJson(`/v1/admin/colors`, json);
    if (result.data) {
      return new CreateOrEditColorResult(true, null, ColorInfo.ParseJson(result.data));
    }
    return new BaseResult(false, result.error.message);
  }

  /**
* @param {string} colorId 
* @param {string} name 
* @returns {Promise<CreateOrEditColorResult>}
*/
  async editColor(colorId, name) {
    const json = { name };

    const result = await HttpClient.PutJson(`/v1/admin/colors/${colorId}`, json);
    if (result.data) {
      return new CreateOrEditColorResult(true, null, ColorInfo.ParseJson(result.data));
    }
    return new BaseResult(false, result.error.message);
  }

  /**
* @param {string} colorId 
* @returns {Promise<BaseResult>}
*/
  async deleteColor(colorId) {
    const result = await HttpClient.Delete(`/v1/admin/colors/${colorId}`);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }
}