/**
 * @typedef {Object} ErrorResult 
 * @property {string} message
 * @property {any} data
 */

import api from "./api";

class ServerResult {
    data = null;
    /**
     * @type {ErrorResult | null}
     */
    error = null;
}

export class BaseResult {
    /**@type {boolean} */
    success;

    /**@type {string} */
    errorMessage;

    /**
     * 
     * @param {boolean} success 
     * @param {string} errorMessage 
     */
    constructor(success, errorMessage) {
        this.success = success;
        this.errorMessage = errorMessage;
    }
}

export default class HttpClient {

    static BaseUrl = "https://api.fm.kg";

    static async Fetch(url, method, body, contentType) {
        try {
            const header = new Headers({})
            if (api.token)
                header.set('Authorization', api.token);

            if (contentType)
                header.set('Content-Type', contentType);

            const requestOptions = {
                method: method,
                body: body,
                headers: header
            };

            const fetchResponse = await fetch(`${HttpClient.BaseUrl}${url}`, requestOptions);

            const json = await fetchResponse.json();

            const result = new ServerResult();
            result.data = json.data;
            result.error = json.error;
            return result;
        }
        catch (e) {
            console.log(e);
            const result = new ServerResult();
            result.error = { message: e.message };
            return result;
        }
    }

    /**
     * @returns {Promise<ServerResult>}
     */
    static async Get(url) {
        return this.Fetch(url, "GET", undefined);
    }

    /**
     * @param {string} url 
     * @param {FormData} formData
     * @returns {Promise<ServerResult>}
     */
    static async Post(url, formData) {
        return this.Fetch(url, "POST", formData);
    }

    /**
 * @param {string} url 
 * @param {JSON} jsonObject
 * @returns {Promise<ServerResult>}
 */
    static async PostJson(url, jsonObject) {
        return this.Fetch(url, "POST", JSON.stringify(jsonObject), 'application/json');
    }

    /**
    * @param {string} url 
    * @param {JSON} jsonObject
    * @returns {Promise<ServerResult>}
    */
    static async PutJson(url, jsonObject) {
        return this.Fetch(url, "PUT", JSON.stringify(jsonObject), 'application/json');
    }

    /**
 * @param {string} url 
 * @param {FormData} formData
 * @returns {Promise<ServerResult>}
 */
    static async Put(url, formData) {
        return this.Fetch(url, "PUT", formData);
    }

    /**
* @param {string} url 
* @returns {Promise<ServerResult>}
*/
    static async Delete(url) {
        return this.Fetch(url, "DELETE", undefined);
    }
}