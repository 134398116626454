<template>
  <div class="text-center">
    <v-dialog
            v-model="show"
            width="500"
    >
      <v-card>
        <v-card-title class="headline red lighten-2">
          {{header}}
        </v-card-title>

        <v-card-text>
          {{message}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
                  color="primary"
                  text
                  @click="okClick">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: "ModalError",
    props: {show: Boolean, header: String, message: String, okClicked: Function},
    methods: {
      okClick() {
        if (this.okClicked)
          this.okClicked();
      },
    }
  }
</script>