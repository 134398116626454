import HttpClient, {BaseResult} from "../httpClient";

export class AdvertInfo {
  /**@type {string} */
  id;
  /**@type {null|string} */
  description;
  /**@type {boolean} */
  isApproved; /* Одобрен модератором */
  /**@type {boolean} */
  isActive; /* Активно */
  /**@type {boolean} */
  isSold; /* Продано! */
  /**@type {number} */
  price; /* Цена */
  /**@type {boolean} */
  isNew; /* Новый */
}

export class AdvertsPagedList {
  /**@type {AdvertInfo[]} */
  items;
  /**@type {Pagination} */
  pagination;
}

export class Adverts {
  /**
   * @param {number} page № страницы
   * @param {number} perPage Кол-во результатов на странице
   * @param {string|null} sortField Поле для сортировки (createdAt | price)
   * @param {string|null} sortDir Направление сортировки (asc | desc)
   * @param {string|null} categoryId
   * @param {string|null} brandId
   * @param {string|null} searchText
   * @param {boolean|null} bannedAndEdited
   * @returns {Promise<AdvertsPagedList>}
   */
  async getPagedList(page, perPage, sortField, sortDir , categoryId, brandId, searchText, bannedAndEdited) {
    const sort = sortField && sortDir ? `&sortField=${sortField}&sortDir=${sortDir}`: "";
    const category = categoryId ? `&categoryId=${categoryId}` : "";
    const brand = brandId ? `&brandId=${brandId}` : "";
    const search = searchText ? `&search=${searchText}` : "";
    const bannedAndEditedParam = bannedAndEdited ? `&bannedAndEdited=true` : "";

    const result = await HttpClient.Get(`/v1/admin/adverts?page=${page}&perPage=${perPage}${sort}${category}${brand}${search}${bannedAndEditedParam}`);
    if (result.data) {
      result.data.success = true;
      return result.data;
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} advertId
   * @returns {Promise<ServerResult>}
   */
  async getAdvert(advertId) {
    return await HttpClient.Get(`/v1/admin/adverts/${advertId}`)
  }

  /**
   * @param {string} advertId
   * @returns {Promise<ServerResult>}
   */
  async getAdvertActions(advertId) {
    return await HttpClient.Get(`/v1/admin/adverts/${advertId}/logs`)
  }

  /**
   * @param {string} advertId
   * @param {string} reason
   * @returns {Promise<BaseResult>}
   */
  async banAdvert(advertId, reason) {
    const data = {reason};
    const result = await HttpClient.PostJson(`/v1/admin/adverts/${advertId}/ban`, data);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} advertId
   * @returns {Promise<BaseResult>}
   */
  async unbanAdvert(advertId) {
    const result = await HttpClient.Post(`/v1/admin/adverts/${advertId}/unban`);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }
}