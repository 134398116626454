import api from './api';
import HttpClient, { BaseResult } from './httpClient';

class Email {
    /**
     * @param {string} email 
     * @returns {Promise<ServerResult>}
     */
    async sendOTP(email) {
        const result = await HttpClient.Get("/v1/user/profile" + email);
        return result;
    }

    /**
     * @param {string} email 
     * @param {string} otp 
     * @param {string} device Device decription 
     */
    getToken(email, otp, device) {
        return email + otp + device;
    }
}

export class GetTokenResult extends BaseResult {
    /**@type {string | null} */
    token;
    /**@type {boolean} */
    isFirstTime;

    constructor(success, errorMessage, token, isFirstTime) {
        super(success, errorMessage);
        this.token = token;
        this.isFirstTime = isFirstTime;
    }
}


class Phone {
    /**
     * @param {string} phone Number: 996*********
     * @returns {Promise<BaseResult>}
     */
    async sendOTP(phone) {
        //var formdata = new FormData();
        //formdata.append("phone", phone);
        const json = {
            phone: phone
        }
        const result = await HttpClient.PostJson("/v1/security/phone/sendOTP", json);
        if (result.data) {
            return new BaseResult(result.data?.success === true, null);
        }
        return new BaseResult(false, result.error.message);
    }

    async setOTP(phone) {
        //var formdata = new FormData();
        //formdata.append("phone", phone);
        const json = {
            phone: phone
        }
        const result = await HttpClient.PostJson("/v1/admin/security/phone/sendOTP", json);
        if (result.data) {
            return new BaseResult(result.data?.success === true, null);
        }
        return new BaseResult(false, result.error.message);
    }

    /**
     * @param {string} phone Number: 996********* 
     * @param {string} otp 
     * @param {string} device Device decription 
     * @returns {Promise<GetTokenResult>}
     */
    async getToken(phone, otp, device) {
        const json = {
            phone,
            otp,
            device
        };
        const result = await HttpClient.PostJson("/v1/security/phone/getToken", json);
        if (result.data) {
            api.token = result.data.token;
            return new GetTokenResult(true, null, result.data.token, result.data.isFirstTime);
        }
        return new GetTokenResult(false, result.error.message, null, false);
    }
}


export default class Security {
    #email = new Email();
    /**
    * @type {Email}
    */
    get email() {
        return this.#email;
    }

    #phone = new Phone();
    /**
    * @type {Phone}
    */
    get phone() {
        return this.#phone;
    }
}