import { Pagination } from "../commonStucts";
import HttpClient, { BaseResult } from "../httpClient";

export class AdvertCondition {
  /**@type {string} */
  id;
  /**@type {string} */
  name;
  /**@type {string|null} */
  description;

  /**
   * @param {string} id 
   * @param {string} name 
   * @param {string} name 
   */
  constructor(id, name, description) {
    this.id = id;
    this.name = name;
    this.description = description;
  }

  /**
   * @param {any} json
   * @returns {AdvertCondition|null} 
   */
  static ParseJson(json) {
    return json ? new AdvertCondition(json.id, json.name, json.description) : null;
  }
}

export class AdvertConditionPagedList {
  /**@type {AdvertCondition[]} */
  items;
  /**@type {Pagination} */
  pagination;

  /**
   * @param {AdvertCondition[]} items 
   * @param {Pagination} pagination 
   */
  constructor(items, pagination) {
    this.items = items;
    this.pagination = pagination;
  }

  /**
   * @param {any} json
   * @returns {AdvertConditionPagedList|null} 
   */
  static ParseJson(json) {
    if (json) {
      const items = json.items ? json.items.map(v => AdvertCondition.ParseJson(v)) : [];
      const pagination = Pagination.ParseJson(json.pagination);
      return new AdvertConditionPagedList(items, pagination);
    }
    return null;
  }
}

export class CreateOrEditAdvertConditionResult extends BaseResult {
  /**@type {AdvertCondition} */
  advertCondition;

  constructor(success, errorMessage, advertCondition) {
    super(success, errorMessage);
    this.advertCondition = advertCondition;
  }
}

export class AdvertConditions {
  /**
 * @param {number} page № страницы
 * @param {number} perPage Кол-во результатов на странице
 * @returns {Promise<AdvertConditionPagedList>}
 */
  async getPagedList(page, perPage) {
    const result = await HttpClient.Get(`/v1/admin/adverts/conditions?page=${page}&perPage=${perPage}`);
    if (result.data) {
      return AdvertConditionPagedList.ParseJson(result.data);
    }
    return new BaseResult(false, result.error.message);
  }

  /**
 * @param {string} name 
 * @returns {Promise<CreateOrEditAdvertConditionResult>}
 */
  async createAdvertCondition(name, description) {
    const json = { name, description };

    const result = await HttpClient.PostJson(`/v1/admin/adverts/conditions`, json);
    if (result.data) {
      return new CreateOrEditAdvertConditionResult(true, null, AdvertCondition.ParseJson(result.data));
    }
    return new BaseResult(false, result.error.message);
  }

  /**
* @param {string} advertConditionId 
* @param {string} name 
* @returns {Promise<CreateOrEditAdvertConditionResult>}
*/
  async editAdvertCondition(advertConditionId, name, description) {
    const json = { name, description };

    const result = await HttpClient.PutJson(`/v1/admin/adverts/conditions/${advertConditionId}`, json);
    if (result.data) {
      return new CreateOrEditAdvertConditionResult(true, null, AdvertCondition.ParseJson(result.data));
    }
    return new BaseResult(false, result.error.message);
  }

  /**
* @param {string} advertConditionId 
* @returns {Promise<BaseResult>}
*/
  async deleteAdvertCondition(advertConditionId) {
    const result = await HttpClient.Delete(`/v1/admin/adverts/conditions/${advertConditionId}`);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }
}