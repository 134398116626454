<template>
  <v-card tile>
    <v-list dense>
      <v-autocomplete class="mx-2"
                      v-model="selectedColor"
                      :items="colors"
                      item-text="name"
                      item-value="id"
                      label="Цвет"
                      clearable
                      return-object
      ></v-autocomplete>

      <v-list-item-group v-model="selectedColorIndex" color="primary" style="height: 450px"  class="overflow-y-auto">
        <v-list-item v-for="(item, i) in productColors" :key="i">
          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-btn
              v-show="selectedColorIndex >= 0"
              color="error"
              elevation="2"
              v-on:click="showDeleteDialog = true"
      >
        Удалить цвет
      </v-btn>
    </v-list>
    <ModalDialog :show="showDeleteDialog" :header="'Удаление'" :message="'Вы действиетльно хотите удалить цвет?'"
                 :yesClicked="productColorDelete" :noClicked="()=>showDeleteDialog = false"/>
    <ModalError :show="showError" :header="'Ошибка'" :message="errorMessage" :okClicked="()=>showError=false"/>
  </v-card>
</template>

<script>
  import api from "../../api/api";
  import ModalDialog from "../common/ModalDialog";
  import ModalError from "../common/ModalError";

  export default {
    name: "ProductColorEditor",
    components: {ModalError, ModalDialog},
    props: {selectedProduct: Object, colors: Array},
    data() {
      return {
        selectedColorIndex: -1,
        selectedColor: null,
        productColors: [],
        showDeleteDialog: false,
        showError: false,
        errorMessage: ""
      }
    },
    watch: {
      selectedColor() {
        if (this.selectedColor) this.addColor();
      },
      selectedProduct() {
        this.productColors = this.selectedProduct ? this.selectedProduct.colors : [];
        this.selectedColorIndex = -1;
      }
    },
    methods: {
      async addColor() {
        const index = this.productColors.findIndex(
            (p) => p.id === this.selectedColor.id
        );
        if (index > -1) {
          this.selectedColor = null;
          return;
        }

        const result = await api.admin.products.addProductColor(
            this.selectedProduct.id,
            this.selectedColor.id
        );
        if (result.success) {
          this.productColors.push(this.selectedColor);
          this.selectedColor = null;
          this.selectedColorIndex = -1;
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
      },

      async productColorDelete() {
        this.showDeleteDialog = false;
        const color = this.productColors[this.selectedColorIndex];
        const result = await api.admin.products.deleteProductColor(
            this.selectedProduct.id,
            color.id
        );
        if (result.success) {
          this.productColors.splice(this.selectedColorIndex, 1);
          this.selectedColorIndex = -1;
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
      },
    }
  }
</script>
