import HttpClient, { BaseResult } from "../httpClient";
import { Field, FieldGroup, Variant, VariantGroup } from "./category";

export class EditVariantGroupsResult extends BaseResult {
  /**@type {VariantGroup} */
  varianrGroup;

  constructor(success, errorMessage, varianrGroup) {
    super(success, errorMessage);
    this.varianrGroup = varianrGroup;
  }
}

export class CreateOrEditVariantResult extends BaseResult {
  /**@type {Variant} */
  variant;

  constructor(success, errorMessage, variant) {
    super(success, errorMessage);
    this.variant = variant;
  }
}

export class EditFieldGroupsResult extends BaseResult {
  /**@type {FieldGroup} */
  fieldGroup;

  constructor(success, errorMessage, fieldGroup) {
    super(success, errorMessage);
    this.fieldGroup = fieldGroup;
  }
}

export class CreateOrEditFieldResult extends BaseResult {
  /**@type {Field} */
  field;

  constructor(success, errorMessage, field) {
    super(success, errorMessage);
    this.field = field;
  }
}

export class Specs {
  /**
   * @param {string} variantGroupId 
   * @param {string} name 
   * @returns {Promise<EditVariantGroupsResult>}
   */
  async editVariantGroups(variantGroupId, name) {
    const json = { name };

    const result = await HttpClient.PutJson(`/v1/admin/specs/variantGroups/${variantGroupId}`, json);
    if (result.data) {
      return new EditVariantGroupsResult(true, null, VariantGroup.ParseJson(result.data));
    }
    return new BaseResult(false, result.error.message);
  }

  /**
* @param {string} variantGroupId 
* @returns {Promise<BaseResult>}
*/
  async deleteVariantGroup(variantGroupId) {
    const result = await HttpClient.Delete(`/v1/admin/specs/variantGroups/${variantGroupId}`, undefined);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }

  /**
 * @param {string} variantGroupId 
 * @param {string} name 
 * @returns {Promise<CreateOrEditVariantResult>}
 */
  async createVariant(variantGroupId, name) {
    const json = { name };

    const result = await HttpClient.PostJson(`/v1/admin/specs/variantGroups/${variantGroupId}`, json);
    if (result.data) {
      return new CreateOrEditVariantResult(true, null, Variant.ParseJson(result.data));
    }
    return new BaseResult(false, result.error.message);
  }

  /**
 * @param {string} variantId 
 * @param {string} name 
 * @returns {Promise<CreateOrEditVariantResult>}
 */
  async editVariant(variantId, name) {
    const json = { name };

    const result = await HttpClient.PutJson(`/v1/admin/specs/variants/${variantId}`, json);
    if (result.data) {
      return new CreateOrEditVariantResult(true, null, Variant.ParseJson(result.data));
    }
    return new BaseResult(false, result.error.message);
  }

  /**
* @param {string} variantId 
* @returns {Promise<BaseResult>}
*/
  async deleteVariant(variantId) {
    const result = await HttpClient.Delete(`/v1/admin/specs/variants/${variantId}`);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }

  /**
 * @param {string} fieldGroupId 
 * @param {string} name 
 * @returns {Promise<EditFieldGroupsResult>}
 */
  async editFieldGroups(fieldGroupId, name) {
    const json = { name };

    const result = await HttpClient.PutJson(`/v1/admin/specs/fieldGroups/${fieldGroupId}`, json);
    if (result.data) {
      return new EditFieldGroupsResult(true, null, FieldGroup.ParseJson(result.data));
    }
    return new BaseResult(false, result.error.message);
  }

  /**
* @param {string} fieldGroupId 
* @returns {Promise<BaseResult>}
*/
  async deleteFieldGroup(fieldGroupId) {
    const result = await HttpClient.Delete(`/v1/admin/specs/fieldGroups/${fieldGroupId}`, undefined);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }

    /**
 * @param {string} fieldGroupId 
 * @param {string} name 
 * @returns {Promise<CreateOrEditFieldResult>}
 */
async createField(fieldGroupId, name) {
  const json = { name };

  const result = await HttpClient.PostJson(`/v1/admin/specs/fieldGroups/${fieldGroupId}`, json);
  if (result.data) {
    return new CreateOrEditFieldResult(true, null, Field.ParseJson(result.data));
  }
  return new BaseResult(false, result.error.message);
}

/**
* @param {string} fieldId 
* @param {string} name 
* @returns {Promise<CreateOrEditFieldResult>}
*/
async editField(fieldId, name) {
  const json = { name };

  const result = await HttpClient.PutJson(`/v1/admin/specs/fields/${fieldId}`, json);
  if (result.data) {
    return new CreateOrEditFieldResult(true, null, Field.ParseJson(result.data));
  }
  return new BaseResult(false, result.error.message);
}

/**
* @param {string} fieldId 
* @returns {Promise<BaseResult>}
*/
async deleteField(fieldId) {
  const result = await HttpClient.Delete(`/v1/admin/specs/fields/${fieldId}`);
  if (result.data) {
    return new BaseResult(result.data.success, null);
  }
  return new BaseResult(false, result.error.message);
}
}