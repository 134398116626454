<template>
  <v-card outlined>
    <v-form>
      <v-container>
        <!-- Описание -->
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
                    v-model="productName"
                    label="Название"
                    required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete
                    v-model="selectedBrand"
                    :items="brands"
                    item-text="name"
                    item-value="id"
                    label="Бренд"
                    clearable
                    return-object
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-menu
                    ref="menuDate"
                    v-model="menuDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                        v-model="releasedAt"
                        label="Дата релиза"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                      ref="picker"
                      v-model="releasedAt"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="2000-01-01"
                      @change="saveDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field v-if="selectedProduct"
                          v-model="selectedCategoryText"
                          label="Категория"
                          readonly
                          disabled
            ></v-text-field>
            <v-autocomplete v-else
                            v-model="selectedCategory"
                            :items="categories"
                            item-text="name"
                            item-value="id"
                            label="Категория"
                            clearable
                            return-object
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-checkbox
                    v-model="productIsActive"
                    label="Активно"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-card-actions>
      <v-btn
              color="primary"
              elevation="2"
              :disabled="isDisabledButtonSave"
              v-on:click="productSave"
      >
        {{ buttonSaveText }}
      </v-btn>

      <v-btn
              v-show="selectedProduct"
              color="error"
              elevation="2"
              v-on:click="showDeleteDialog = true"
      >
        Удалить продукт
      </v-btn>
    </v-card-actions>

    <ModalDialog :show="showDeleteDialog" :header="'Удаление'" :message="'Вы действиетльно хотите удалить продукт?'"
                 :yesClicked="productDelete" :noClicked="()=>showDeleteDialog = false"/>
    <ModalError :show="showError" :header="'Ошибка'" :message="errorMessage" :okClicked="()=>showError=false"/>
  </v-card>
</template>

<script>
  import api from "../../api/api";
  import ModalDialog from "../common/ModalDialog";
  import ModalError from "../common/ModalError";

  export default {
    name: "ProductMainInfoEditor",
    components: {ModalError, ModalDialog},
    props: {
      selectedProduct: Object,
      brands: Array,
      categories: Array,
      selectedCategoryChanged: Function,
      selectedProductChanged: Function,
      addedProduct: Function,
      deletedProduct: Function,
    },
    data() {
      return {
        productName: "",
        releasedAt: "",
        productIsActive: false,
        selectedCategory: null,
        selectedCategoryText: "",
        selectedBrand: null,

        date: null,
        menuDate: false,
        showDeleteDialog: false,
        showError: false,
        errorMessage: ""
      }
    },
    watch: {
      menuDate(val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
      },
      selectedProduct() {
        /**@type {Product | null} */
        const product = this.selectedProduct;
        this.productName = product ? product.name : "";
        this.selectedBrand = product ? product.brand : null;
        this.releasedAt = product ? product.releasedAt : "";

        this.selectedCategory = product
            ? this.categories.find((c) => c.id === product.category.id)
            : "";
        this.selectedCategoryText = this.selectedCategory ? this.selectedCategory.name : "";
        this.productIsActive = product ? product.isActive : "";
      },
      selectedCategory() {
        this.selectedCategoryChanged(this.selectedCategory);
      }
    },
    computed: {
      buttonSaveText() {
        return this.selectedProduct ? "Сохранить" : "Добавить";
      },
      isDisabledButtonSave() {
        return !(
            this.productName !== "" &&
            this.releasedAt !== "" &&
            this.selectedCategory &&
            this.selectedBrand
        );
      },
    },
    methods: {
      saveDate(date) {
        this.$refs.menuDate.save(date);
      },

      async productSave() {
        /**@type {Product | null} */
        const product = this.selectedProduct;
        if (product) {
          const result = await api.admin.products.updateProduct(
              product.id,
              this.productName,
              this.releasedAt,
              this.selectedBrand.id,
              this.productIsActive
          );
          if (result.success) {
            this.selectedProductChanged(result);
          } else {
            this.errorMessage = result.errorMessage;
            this.showError = true;
            return;
          }
        } else {
          const result = await api.admin.products.createProduct(
              this.productName,
              this.releasedAt,
              this.selectedBrand.id,
              this.selectedCategory.id,
              this.productIsActive
          );
          if (result.success) {
            this.addedProduct(result);
          } else {
            this.errorMessage = result.errorMessage;
            this.showError = true;
            return;
          }
        }
      },

      async productDelete() {
        this.showDeleteDialog = false;
        const result = await api.admin.products.deleteProduct(
            this.selectedProduct.id
        );
        if (result.success) {
          this.deletedProduct(this.selectedProduct.id);
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
      },
    }
  }
</script>