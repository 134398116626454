<template>
  <v-container>
    <FeedbacksList />
    <ComplaintsList />
  </v-container>
</template>

<script>
import ComplaintsList from "@/components/dashboard/ComplaintsList";
import FeedbacksList from "@/components/dashboard/FeedbacksList";
export default {
  name: "DashboardPage",
  components: {ComplaintsList, FeedbacksList},
  data: () => ({
  }),
  created: function () {
  },
  watch: {
  },
  computed: {
  },
  methods: {
  },
};
</script>