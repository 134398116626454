var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"text-center"},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","headers":_vm.brandHeaders,"items":_vm.brands,"options":_vm.brandOptions,"server-items-length":_vm.totalBrands,"loading":_vm.loading,"single-select":true,"footer-props":{'items-per-page-options': [10, 50, 100, 500]}},on:{"update:options":function($event){_vm.brandOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Бренды")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Добавить ")])]}}]),model:{value:(_vm.brandDialog),callback:function ($$v) {_vm.brandDialog=$$v},expression:"brandDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"label":"Бренд"},model:{value:(_vm.editedBrand.name),callback:function ($$v) {_vm.$set(_vm.editedBrand, "name", $$v)},expression:"editedBrand.name"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{attrs:{"label":"Активно"},model:{value:(_vm.editedBrand.isActive),callback:function ($$v) {_vm.$set(_vm.editedBrand, "isActive", $$v)},expression:"editedBrand.isActive"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.brandClose}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.brandSave}},[_vm._v(" Сохранить ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.brandDialogDelete),callback:function ($$v) {_vm.brandDialogDelete=$$v},expression:"brandDialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Удалить запись?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.brandCloseDelete}},[_vm._v("Нет")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteBrandConfirm}},[_vm._v("Да")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.image)?_c('img',{attrs:{"src":item.image ? item.image.thumbUrl : '',"width":"80","height":"80"}}):_vm._e()]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.isActive ? 'green--text' : 'red--text'},[_vm._v(_vm._s(item.isActive ? "Да" : "Нет"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editBrand(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteBrand(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return undefined},proxy:true}],null,true)})],1)],1),_c('ModalError',{attrs:{"show":_vm.showError,"header":"Ошибка","message":_vm.errorMessage,"okClicked":function (){ return _vm.showError=false; }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }