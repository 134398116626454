<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <v-data-table
                item-key="id"
                :headers="translationHeaders"
                :items="translations"
                :options.sync="translationOptions"
                :server-items-length="totalTranslations"
                :loading="loading"
                :single-select="true"
                class="elevation-1"
                :footer-props="{'items-per-page-options': [10, 50, 100, 500]}"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Переводы</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="translationDialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                  >
                    Добавить
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                                  v-model="editedTranslation.message"
                                  label="Текст ru"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                                  v-model="editedTranslation.translation"
                                  label="Перевод"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="translationClose">
                      Отмена
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="translationSave">
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="translationDialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Удалить запись?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="translationCloseDelete"
                    >Нет</v-btn
                    >
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteTranslationConfirm"
                    >Да</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editTranslation(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteTranslation(item)"> mdi-delete </v-icon>
          </template>
          <template v-slot:no-data> </template>
        </v-data-table>
      </v-col>
    </v-row>
    <ModalError :show="showError" :header="'Ошибка'" :message="errorMessage" :okClicked="()=>showError=false"/>

  </v-container>
</template>

<script>
  import api from "../api/api";
  import ModalError from "../components/common/ModalError";
  export default {
    name: "TranslationPage",
    components: {ModalError},
    data: () => ({
      selectedTranslations: [],
      totalTranslations: 0,
      translations: [],
      loading: true,
      translationOptions: {},
      translationHeaders: [
        {
          text: "Текст ru",
          align: "start",
          sortable: false,
          value: "message",
        },
        {
          text: "Перевод",
          align: "start",
          sortable: false,
          value: "translation",
        },
        {  value: "actions", sortable: false, width: 80 },
      ],
      editedTranslation: {
        id: "",
        message: "",
        translation: "",
      },
      defaultTranslation: {
        message: "",
        translation: "",
      },
      translationDialog: false,
      translationDialogDelete: false,
      editedIndex: -1,
      showError: false,
      errorMessage: ""
    }),
    computed: {
      formTitle() {
        return this.editedIndex === -1
            ? "Добавить запись"
            : "Редактировать запись";
      },
    },
    watch: {
      translationDialog(val) {
        val || this.translationClose();
      },
      translationDialogDelete(val) {
        val || this.translationCloseDelete();
      },
      translationOptions: {
        handler() {
          this.getTranslations();
        },
        deep: true,
      },
    },
    methods: {
      async getTranslations() {
        this.loading = true;
        const { page, itemsPerPage } = this.translationOptions;
        const result = await api.admin.translations.getPagedList(page, itemsPerPage);
        this.translations = result.items;
        this.totalTranslations = result.pagination.totalItems;
        this.loading = false;
      },

      editTranslation(item) {
        this.editedIndex = this.translations.indexOf(item);
        this.editedTranslation = Object.assign({}, item);
        this.translationDialog = true;
      },

      deleteTranslation(item) {
        this.editedIndex = this.translations.indexOf(item);
        this.editedTranslation = Object.assign({}, item);
        this.translationDialogDelete = true;
      },

      async deleteTranslationConfirm() {
        const result = await api.admin.translations.deleteTranslation(
            this.editedTranslation.id
        );
        if (result.success) {
          this.translations.splice(this.editedIndex, 1);
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
        this.translationCloseDelete();
      },

      translationClose() {
        this.translationDialog = false;
        this.$nextTick(() => {
          this.editedTranslation = Object.assign({}, this.defaultTranslation);
          this.editedIndex = -1;
        });
      },

      translationCloseDelete() {
        this.translationDialogDelete = false;
        this.$nextTick(() => {
          this.editedTranslation = Object.assign({}, this.defaultTranslation);
          this.editedIndex = -1;
        });
      },

      async translationSave() {
        if (this.editedIndex > -1) {
          const result = await api.admin.translations.editTranslation(
              this.editedTranslation.id,
              this.editedTranslation.message,
              this.editedTranslation.translation
          );
          if (result.success) {
            Object.assign(this.translations[this.editedIndex], result);
          } else {
            this.errorMessage = result.errorMessage;
            this.showError = true;
            return;
          }
        } else {
          const result = await api.admin.translations.createTranslation(
              this.editedTranslation.message,
              this.editedTranslation.translation
          );
          if (result.success) {
            this.translations.push(result);
          } else {
            this.errorMessage = result.errorMessage;
            this.showError = true;
            return;
          }
        }
        this.translationClose();
      },
    },
  };
</script>