import Vue from 'vue'
import VueRouter from 'vue-router';
import DashboardPage from "@/pages/DashboardPage";
import CategoryPage from '../pages/CategoryPage'
import LoginPage from '../pages/LoginPage'
import BrandPage from '../pages/BrandPage'
import ColorPage from '../pages/ColorPage'
import ProductPage from '../pages/ProductPage'
import LocationPage from '../pages/LocationPage'
import AdvertConditionPage from '../pages/AdvertConditionPage'
import ComplaintReasonPage from '../pages/ComplaintReasonPage'
import UsersPage from '../pages/UsersPage'
import AdvertsPage from '../pages/AdvertsPage'
import AdvertPage from '../pages/AdvertPage'
import TranslationsPage from '../pages/TranslationsPage'
import OtpPage from "../pages/OtpPage";
import ComplaintPage from "../pages/ComplaintPage";

Vue.use(VueRouter);

const routes = [
  { path: '/', component: DashboardPage },
  { path: '/category', component: CategoryPage },
  { path: '/login', component: LoginPage },
  { path: '/brand', component: BrandPage },
  { path: '/color', component: ColorPage },
  { path: '/product', component: ProductPage },
  { path: '/location', component: LocationPage },
  { path: '/advertCondition', component: AdvertConditionPage },
  { path: '/complaintReason', component: ComplaintReasonPage },
  { path: '/complaints', component: ComplaintPage },
  { path: '/users', component: UsersPage },
  { path: '/adverts', component: AdvertsPage },
  { path: '/adverts/:id', component: AdvertPage },
  { path: '/translations', component: TranslationsPage },
  { path: '/setOtp', component: OtpPage },
];

const router = new VueRouter({ routes });

router.beforeEach((to, from, next) => {
  if (to.path !== '/login' && /*!router.app.$store.state.authorized*/ !localStorage.getItem("token")) next({ path: '/login', replace: true })
  else next()
})

export default router;