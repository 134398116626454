<template>
  <v-card outlined>
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title class="text-h6 mb-2" :class="user.isActive ? 'green--text' : 'red--text'">{{ user.phone }}</v-list-item-title>
        <p class="text-caption"><span class="font-weight-medium">Имя:</span> {{ user.name }}</p>
        <p class="text-caption"><span class="font-weight-medium">Email:</span> {{ user.email }}</p>
        <p class="text-caption"><span class="font-weight-medium">Регистрация:</span> {{ user.createdAt }}</p>
        <p class="text-caption"><span class="font-weight-medium">Объявлений:</span> {{ user.totalAdverts }}</p>

      </v-list-item-content>
      <v-list-item-avatar
          tile
          size="80"
          color="grey"
      >
        <v-img v-if="user.image" :src="user.image.thumbUrl" />
      </v-list-item-avatar>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: "UserCard",
  props: {
    user: Object,
  }
}
</script>