import { Admin } from "./admin/admin";
import Security from "./security";
import { User } from "./user";

class Api {
    #token;
    /**
    * @type {string | null}
    */
    get token() {
        return this.#token;
    }

    set token(token) {
        this.#token = token;
        if(token)
            localStorage.setItem("token", token);
    }

    #security = new Security();
    /**@type {Security}*/
    get security() {
        return this.#security;
    }

    #user = new User();
    /**@type {User} */
    get user() {
        return this.#user;
    }

    #admin = new Admin();
    /**@type {Admin} */
    get admin(){
        return this.#admin;
    }
}

const api = new Api();
export default api;