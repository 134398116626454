<template>
  <v-card tile>
    <v-list dense>
      <v-autocomplete class="mx-2"
                      v-model="selectedField"
                      :items="fields"
                      item-text="name"
                      item-value="id"
                      label="Поля"
                      clearable
                      return-object
      ></v-autocomplete>

      <v-text-field class="mx-2"
                    v-model="selectedFieldValue"
                    label="Значение поля"

                    clearable
                    :append-outer-icon="selectedFieldIndex > -1 ? 'mdi-pencil-outline' : 'mdi-plus-circle-outline'"
                    type="text"
                    @click:append-outer="saveFieldValue"
      ></v-text-field>

      <v-list-item-group v-model="selectedFieldIndex" color="primary"  style="height: 380px"  class="overflow-y-auto">
        <v-list-item v-for="(item, i) in productFields" :key="i">
          <v-list-item-content>
            <v-list-item-title v-text="`${item.name}: ${item.value}`"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-btn
              v-show="selectedFieldIndex >= 0"
              color="error"
              elevation="2"
              v-on:click="showDeleteDialog = true"
      >
        Удалить поле
      </v-btn>
    </v-list>
    <ModalDialog :show="showDeleteDialog" :header="'Удаление'" :message="'Вы действиетльно хотите удалить значение поля?'"
                 :yesClicked="productFieldValueDelete" :noClicked="()=>showDeleteDialog = false"/>
    <ModalError :show="showError" :header="'Ошибка'" :message="errorMessage" :okClicked="()=>showError=false"/>
  </v-card>
</template>

<script>
  import api from "../../api/api";
  import ModalDialog from "../common/ModalDialog";
  import ModalError from "../common/ModalError";

  export default {
    name: "ProductFieldEditor",
    components: {ModalError, ModalDialog},
    props: {selectedProduct: Object, fields: Array},
    data() {
      return {
        selectedField: null,
        selectedFieldValue: "",
        selectedFieldIndex: -1,
        productFields: [],
        showDeleteDialog: false,
        showError: false,
        errorMessage: ""
      }
    },
    watch: {
      selectedProduct() {
        /**@type {Product | null} */
        const product = this.selectedProduct;
        var fields = [];
        if (product) {
          for (const group of product.fieldGroups) {
            for (const field of group.fields) {
              fields.push({
                id: field.value.id,
                name: `${group.name}_${field.name}`,
                value: field.value.value
              });
            }
          }
        }
        this.productFields = fields;
        this.selectedVariantIndex = -1;
      },
      selectedFieldIndex() {
        this.selectedFieldValue = this.selectedFieldIndex > -1 ? this.productFields[this.selectedFieldIndex].value : "";
      }
    },
    methods: {

      async saveFieldValue() {
        if (!this.selectedFieldValue)
          return;

        if (this.selectedField && this.selectedFieldIndex === -1) {
          //-----------add
          const result = await api.admin.products.addProductFieldValue(
              this.selectedProduct.id,
              this.selectedField.id,
              this.selectedFieldValue
          );
          if (result.success) {
            this.productFields.push({
              id: result.id,
              name: this.selectedField.name,
              value: result.value
            });
            this.selectedField = null;
            this.selectedFieldValue = "";
            this.selectedFieldIndex = -1;
          } else {
            this.errorMessage = result.errorMessage;
            this.showError = true;
          }
        } else if (this.selectedFieldIndex > -1) {
          //------------edit
          const field = this.productFields[this.selectedFieldIndex];

          const result = await api.admin.products.editProductFieldValue(
              field.id,
              this.selectedFieldValue
          );
          if (result.success) {
            Object.assign(this.productFields[this.selectedFieldIndex], {
              id: result.id,
              name: field.name,
              value: result.value
            });
            this.selectedField = null;
            this.selectedFieldValue = "";
            this.selectedFieldIndex = -1;
          } else {
            this.errorMessage = result.errorMessage;
            this.showError = true;
          }
        }
      },

      async productFieldValueDelete() {
        this.showDeleteDialog = false;
        const fieldValue = this.productFields[this.selectedFieldIndex];
        const result = await api.admin.products.deleteProductFieldValue(
            fieldValue.id
        );
        if (result.success) {
          this.productFields.splice(this.selectedFieldIndex, 1);
          this.selectedFieldIndex = -1;
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
      },
    }
  }
</script>