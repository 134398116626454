<template>
  <v-container>

    <v-row class="text-center">
      <v-col>
        <v-data-table
                item-key="id"
                :headers="complaintHeaders"
                :items="complaints"
                :options.sync="complaintOptions"
                :server-items-length="totalComplaints"
                :loading="loading"
                :single-select="true"
                class="elevation-1"
                :footer-props="{'items-per-page-options': [10, 50, 100, 500]}"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Жалобы</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="showAdvert(item)">
              mdi-information-outline
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import api from "../api/api";
  export default {
    name: "ComplaintPage",
    data: () => ({
      selectedComplaints: [],
      totalComplaints: 0,
      complaints: [],
      loading: true,
      complaintOptions: {},
      complaintHeaders: [
        {
          text: "Причина",
          align: "start",
          sortable: false,
          value: "reason.name",
        },
        {
          text: "Время",
          align: "start",
          sortable: false,
          value: "datetime",
        },
        {
          text: "Описание",
          align: "start",
          sortable: false,
          value: "description",
        },
        {
          text: "Продукт",
          align: "start",
          sortable: false,
          value: "advert.product.name",
        },
        { value: "actions", sortable: false, width:40 },
      ]
    }),
    watch: {
      complaintOptions: {
        handler() {
          this.getComplaints();
        },
        deep: true,
      },
    },
    methods: {
      async getComplaints() {
        this.loading = true;
        const { page, itemsPerPage } = this.complaintOptions;
        const result = await api.admin.complaintReasons.getComplaints(page, itemsPerPage);
        this.complaints = result.items;
        this.totalComplaints = result.pagination.totalItems;
        this.loading = false;
      },
      showAdvert(item){
        window.open('https://fm.kg/details/'+item.advert.id, '_blank').focus();
      }
    },
  };
</script>