import { Pagination } from "../commonStucts";
import HttpClient, { BaseResult } from "../httpClient";

export class Location {
  /**@type {string} */
  id;
  /**@type {string} */
  name;

  /**
   * @param {string} id 
   * @param {string} name 
   */
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  /**
   * @param {any} json
   * @returns {Location|null} 
   */
  static ParseJson(json) {
    return json ? new Location(json.id, json.name) : null;
  }
}

export class LocationPagedList {
  /**@type {Location[]} */
  items;
  /**@type {Pagination} */
  pagination;

  /**
   * @param {Location[]} items 
   * @param {Pagination} pagination 
   */
  constructor(items, pagination) {
    this.items = items;
    this.pagination = pagination;
  }

  /**
   * @param {any} json
   * @returns {LocationPagedList|null} 
   */
  static ParseJson(json) {
    if (json) {
      const locations = json.items ? json.items.map(v => Location.ParseJson(v)) : [];
      const pagination = Pagination.ParseJson(json.pagination);
      return new LocationPagedList(locations, pagination);
    }
    return null;
  }
}

export class CreateOrEditLocationResult extends BaseResult {
  /**@type {Location} */
  location;

  constructor(success, errorMessage, location) {
    super(success, errorMessage);
    this.location = location;
  }
}

export class Locations {
  /**
 * @param {number} page № страницы
 * @param {number} perPage Кол-во результатов на странице
 * @returns {Promise<LocationPagedList>}
 */
  async getPagedList(page, perPage) {
    const result = await HttpClient.Get(`/v1/admin/locations?page=${page}&perPage=${perPage}`);
    if (result.data) {
      return LocationPagedList.ParseJson(result.data);
    }
    return new BaseResult(false, result.error.message);
  }

  /**
 * @param {string} name 
 * @returns {Promise<CreateOrEditLocationResult>}
 */
  async createLocation(name) {
    const json = { name };

    const result = await HttpClient.PostJson(`/v1/admin/locations`, json);
    if (result.data) {
      return new CreateOrEditLocationResult(true, null, Location.ParseJson(result.data));
    }
    return new BaseResult(false, result.error.message);
  }

  /**
* @param {string} locationId 
* @param {string} name 
* @returns {Promise<CreateOrEditLocationResult>}
*/
  async editLocation(locationId, name) {
    const json = { name };

    const result = await HttpClient.PutJson(`/v1/admin/locations/${locationId}`, json);
    if (result.data) {
      return new CreateOrEditLocationResult(true, null, Location.ParseJson(result.data));
    }
    return new BaseResult(false, result.error.message);
  }

  /**
* @param {string} locationId 
* @returns {Promise<BaseResult>}
*/
  async deleteLocation(locationId) {
    const result = await HttpClient.Delete(`/v1/admin/locations/${locationId}`);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }
}