var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","show-select":"","headers":_vm.categoryHeaders,"items":_vm.categories,"options":_vm.categoryOptions,"server-items-length":_vm.totalCategories,"loading":_vm.loading,"single-select":true,"footer-props":{'items-per-page-options': [10, 50, 100, 500]}},on:{"update:options":function($event){_vm.categoryOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Категории")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Добавить ")])]}}]),model:{value:(_vm.categoryDialog),callback:function ($$v) {_vm.categoryDialog=$$v},expression:"categoryDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"label":"Категория"},model:{value:(_vm.editedCategory.name),callback:function ($$v) {_vm.$set(_vm.editedCategory, "name", $$v)},expression:"editedCategory.name"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{attrs:{"label":"Активно"},model:{value:(_vm.editedCategory.isActive),callback:function ($$v) {_vm.$set(_vm.editedCategory, "isActive", $$v)},expression:"editedCategory.isActive"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.categoryClose}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.categorySave}},[_vm._v(" Сохранить ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.categoryDialogDelete),callback:function ($$v) {_vm.categoryDialogDelete=$$v},expression:"categoryDialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Удалить запись?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.categoryCloseDelete}},[_vm._v("Нет ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteCategoryConfirm}},[_vm._v("Да ")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.isActive ? 'green--text' : 'red--text'},[_vm._v(_vm._s(item.isActive ? "Да" : "Нет"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editCategory(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteCategory(item)}}},[_vm._v(" mdi-delete")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset")])]},proxy:true}],null,true),model:{value:(_vm.selectedCategories),callback:function ($$v) {_vm.selectedCategories=$$v},expression:"selectedCategories"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","show-select":"","headers":_vm.variantGroupHeaders,"items":_vm.variantGroups,"single-select":true,"footer-props":{'items-per-page-options': [10, 50, 100, 500]}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Группы вариантов")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.selectedCategory),expression:"selectedCategory"}],staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Добавить ")])]}}]),model:{value:(_vm.variantGroupDialog),callback:function ($$v) {_vm.variantGroupDialog=$$v},expression:"variantGroupDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Группа вариантов"},model:{value:(_vm.editedVariantGroup.name),callback:function ($$v) {_vm.$set(_vm.editedVariantGroup, "name", $$v)},expression:"editedVariantGroup.name"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.variantGroupClose}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.variantGroupSave}},[_vm._v(" Сохранить ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.variantGroupDialogDelete),callback:function ($$v) {_vm.variantGroupDialogDelete=$$v},expression:"variantGroupDialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Удалить запись? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.variantGroupCloseDelete}},[_vm._v("Нет ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteVariantGroupConfirm}},[_vm._v("Да ")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editVariantGroup(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteVariantGroup(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return undefined},proxy:true}],null,true),model:{value:(_vm.selectedVariantGroups),callback:function ($$v) {_vm.selectedVariantGroups=$$v},expression:"selectedVariantGroups"}})],1),_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","headers":_vm.variantHeaders,"items":_vm.variants,"footer-props":{'items-per-page-options': [10, 50, 100, 500]}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Варианты")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.selectedVariantGroup),expression:"selectedVariantGroup"}],staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Добавить ")])]}}]),model:{value:(_vm.variantDialog),callback:function ($$v) {_vm.variantDialog=$$v},expression:"variantDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Вариант"},model:{value:(_vm.editedVariant.name),callback:function ($$v) {_vm.$set(_vm.editedVariant, "name", $$v)},expression:"editedVariant.name"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.variantClose}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.variantSave}},[_vm._v(" Сохранить ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.variantDialogDelete),callback:function ($$v) {_vm.variantDialogDelete=$$v},expression:"variantDialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Удалить запись?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.variantCloseDelete}},[_vm._v("Нет ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteVariantConfirm}},[_vm._v("Да ")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editVariant(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteVariant(item)}}},[_vm._v(" mdi-delete")])]}},{key:"no-data",fn:function(){return undefined},proxy:true}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","show-select":"","headers":_vm.fieldGroupHeaders,"items":_vm.fieldGroups,"single-select":true,"footer-props":{'items-per-page-options': [10, 50, 100, 500]}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Группы полей")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.selectedCategory),expression:"selectedCategory"}],staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Добавить ")])]}}]),model:{value:(_vm.fieldGroupDialog),callback:function ($$v) {_vm.fieldGroupDialog=$$v},expression:"fieldGroupDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Группа полей"},model:{value:(_vm.editedFieldGroup.name),callback:function ($$v) {_vm.$set(_vm.editedFieldGroup, "name", $$v)},expression:"editedFieldGroup.name"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.fieldGroupClose}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.fieldGroupSave}},[_vm._v(" Сохранить ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.fieldGroupDialogDelete),callback:function ($$v) {_vm.fieldGroupDialogDelete=$$v},expression:"fieldGroupDialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Удалить запись? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.fieldGroupCloseDelete}},[_vm._v("Нет ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteFieldGroupConfirm}},[_vm._v("Да ")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.isActive ? "Да" : "Нет"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editFieldGroup(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteFieldGroup(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return undefined},proxy:true}],null,true),model:{value:(_vm.selectedFieldGroups),callback:function ($$v) {_vm.selectedFieldGroups=$$v},expression:"selectedFieldGroups"}})],1),_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","headers":_vm.fieldHeaders,"items":_vm.fields,"footer-props":{'items-per-page-options': [10, 50, 100, 500]}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Поля")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.selectedFieldGroup),expression:"selectedFieldGroup"}],staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Добавить ")])]}}]),model:{value:(_vm.fieldDialog),callback:function ($$v) {_vm.fieldDialog=$$v},expression:"fieldDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Поле"},model:{value:(_vm.editedField.name),callback:function ($$v) {_vm.$set(_vm.editedField, "name", $$v)},expression:"editedField.name"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.fieldClose}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.fieldSave}},[_vm._v(" Сохранить ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.fieldDialogDelete),callback:function ($$v) {_vm.fieldDialogDelete=$$v},expression:"fieldDialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Удалить запись?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.fieldCloseDelete}},[_vm._v("Нет ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteFieldConfirm}},[_vm._v("Да ")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editField(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteField(item)}}},[_vm._v(" mdi-delete")])]}},{key:"no-data",fn:function(){return undefined},proxy:true}],null,true)})],1)],1),_c('ModalError',{attrs:{"show":_vm.showError,"header":'Ошибка',"message":_vm.errorMessage,"okClicked":function (){ return _vm.showError=false; }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }