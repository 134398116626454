<template>
  <v-container>
    <div v-if="loaded">
      <h2>{{ advert.product.brand.name }} {{ advert.product.name }}</h2>
      <div class="table mt-2">
        <template v-for="group in advert.variantGroups">
          <div :key="'a' + group.id" class="table-title">{{ group.name }}</div>
          <div :key="'b' + group.id" class="table-data">{{ group.variant.name }}</div>
        </template>
        <div class="table-title">Цвет</div><div>{{ advert.color.name }}</div>
        <div class="table-title">Опубликовано</div><div><v-icon :color="advert.isActive ? 'green' : 'red'">{{advert.isActive ? "mdi-check" : "mdi-cancel"}} {{advert.isSold ? "Продано" : ""}}</v-icon></div>
        <div class="table-title">Одобрено</div>
        <div v-if="advert.isApproved">
          <v-icon color="success">mdi-check</v-icon>
          <v-btn text small outlined class="ml-1" @click="banAdvertId = advert.id">Забанить</v-btn>
        </div>
        <div v-else>
          <v-icon color="error">mdi-cancel</v-icon>
          <v-btn text small outlined class="ml-1" @click="unbanAdvertId = advert.id">Разбанить</v-btn>
        </div>
        <div class="table-title" v-if="!advert.isApproved">Причина бана</div><div v-if="!advert.isApproved" class="error--text">{{ advert.banReason }}</div>
        <div class="table-title">Цена</div><div>{{ advert.price }}</div>
        <div class="table-title">Описание</div><div>{{ advert.description }}</div>
      </div>
      <v-row v-if="advert.images.length" class="my-5">
        <v-col v-for="image in advert.images" :key="image.id" cols="3" class="d-flex child-flex">
          <v-img :src="image.thumbUrl" @click="showCarousel = true" max-height="200" contain style="cursor: pointer">
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
      <v-dialog
          v-model="showCarousel"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
      >
        <v-card>
          <v-carousel height="100vh">
            <v-carousel-item
                v-for="image in advert.images"
                :key="image.id"
                :src="image.imageUrl"
                contain
            >
              <v-btn
                  class="ma-3"
                  color="blue-grey"
                  fab
                  outlined
                  small
                  @click="showCarousel = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-dialog>
      <UserCard class="mt-4" :user="advert.user" />
      <v-card outlined class="mt-3">
        <v-card-text>
          <div v-for="(action, i) in actions" :key="i" class="action-table">
            <div class="text-caption">{{ action.datetime }}</div>
            <div class="text-caption" :class="action.user.id === advert.user.id ? 'green--text' : 'red--text'">{{ action.user.phone }}</div>
            <div class="text-caption">{{ action.message }}</div>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="error">
      <p class="text-h6 red--text">{{ error }}</p>
    </div>
    <AdvertBan :advert-id="banAdvertId" :success-callback="banSuccess" :cancel-callback="banCancel" />
    <AdvertUnban :advert-id="unbanAdvertId" :success-callback="unbanSuccess" :cancel-callback="unbanCancel" />
  </v-container>
</template>

<script>
  import api from "../api/api";
  import UserCard from "@/components/user/UserCard";
  import AdvertBan from "@/components/adverts/AdvertBan";
  import AdvertUnban from "@/components/adverts/AdvertUnban";

  export default {
    name: "AdvertPage",
    components: {UserCard, AdvertBan, AdvertUnban},
    data: () => ({
      advert: {},
      actions: [],
      loaded: false,
      error: null,
      showCarousel: false,
      banAdvertId: null,
      unbanAdvertId: null,
    }),
    created: function () {
      this.fetchAdvert()
      this.fetchActions()
    },
    methods: {
      async fetchAdvert() {
        const result = await api.admin.adverts.getAdvert(this.$route.params.id)
        if (null !== result.error) {
          this.error = result.error.message
        } else {
          this.advert = result.data
          this.loaded = true
        }
      },
      async fetchActions() {
        const result = await api.admin.adverts.getAdvertActions(this.$route.params.id)
        if (null === result.error) {
          this.actions = result.data
        }
      },
      async banSuccess() {
        this.banAdvertId = null
        await this.fetchAdvert()
        await this.fetchActions()
      },
      banCancel() {
        this.banAdvertId = null
      },
      async unbanSuccess() {
        this.unbanAdvertId = null
        await this.fetchAdvert()
        await this.fetchActions()
      },
      unbanCancel() {
        this.unbanAdvertId = null
      },
    },
  };
</script>

<style scoped>
.table {
  display: grid;
  grid-template-columns: min-content auto;
  gap: 2px 2px;
}

.table div {
  padding: 3px 8px;
  white-space: break-spaces;
}

.table-title {
  background-color: #f9f9f9;
  text-align: right;
  font-weight: 700;
}

.action-table {
  display: grid;
  grid-template-columns: min-content min-content auto;
  gap: 2px 2px;
}

.action-table div {
  padding: 3px 8px;
}
</style>