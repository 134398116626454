import { Pagination } from "../commonStucts";
import HttpClient, { BaseResult } from "../httpClient";

export class Brand {
  /**@type {string} */
  id;
  /**@type {string} */
  name;
  /**@type {boolean} */
  isActive;
  image;

  /**
   * @param {string} id 
   * @param {string} name 
   */
  constructor(id, name, isActive, image) {
    this.id = id;
    this.name = name;
    this.isActive = isActive;
    this.image = image;
  }

  /**
   * @param {any} json
   * @returns {Brand|null} 
   */
  static ParseJson(json) {
    return json ? new Brand(json.id, json.name, json.isActive, json.image) : null;
  }
}

export class BrandPagedList {
  /**@type {Brand[]} */
  items;
  /**@type {Pagination} */
  pagination;

  /**
   * @param {Brand[]} items 
   * @param {Pagination} pagination 
   */
  constructor(items, pagination) {
    this.items = items;
    this.pagination = pagination;
  }

  /**
   * @param {any} json
   * @returns {BrandPagedList|null} 
   */
  static ParseJson(json) {
    if (json) {
      const brands = json.items ? json.items.map(v => Brand.ParseJson(v)) : [];
      const pagination = Pagination.ParseJson(json.pagination);
      return new BrandPagedList(brands, pagination);
    }
    return null;
  }
}

export class CreateOrEditBrandResult extends BaseResult {
  /**@type {Brand} */
  brand;

  constructor(success, errorMessage, brand) {
    super(success, errorMessage);
    this.brand = brand;
  }
}

export class Brands {
  /**
 * @param {number} page № страницы
 * @param {number} perPage Кол-во результатов на странице
 * @returns {Promise<BrandPagedList>}
 */
  async getPagedList(page, perPage) {
    const result = await HttpClient.Get(`/v1/admin/brands?page=${page}&perPage=${perPage}`);
    if (result.data) {
      return BrandPagedList.ParseJson(result.data);
    }
    return new BaseResult(false, result.error.message);
  }

  /**
 * @param {string} name
 * @param {boolean} isActive 
 * @returns {Promise<CreateOrEditBrandResult>}
 */
  async createBrand(name, isActive) {
    const json = { name, isActive };

    const result = await HttpClient.PostJson(`/v1/admin/brands`, json);
    if (result.data) {
      return new CreateOrEditBrandResult(true, null, Brand.ParseJson(result.data));
    }
    return new BaseResult(false, result.error.message);
  }

  /**
* @param {string} brandId 
* @param {string} name 
* @param {boolean} isActive 
* @returns {Promise<CreateOrEditBrandResult>}
*/
  async editBrand(brandId, name, isActive) {
    const json = { name , isActive};

    const result = await HttpClient.PutJson(`/v1/admin/brands/${brandId}`, json);
    if (result.data) {
      return new CreateOrEditBrandResult(true, null, Brand.ParseJson(result.data));
    }
    return new BaseResult(false, result.error.message);
  }

  /**
* @param {string} brandId 
* @returns {Promise<BaseResult>}
*/
  async deleteBrand(brandId) {
    const result = await HttpClient.Delete(`/v1/admin/brands/${brandId}`);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }
}