<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <v-data-table
          item-key="id"
          :headers="locationHeaders"
          :items="locations"
          :options.sync="locationOptions"
          :server-items-length="totalLocations"
          :loading="loading"
          :single-select="true"
          class="elevation-1"
          :footer-props="{'items-per-page-options': [10, 50, 100, 500]}"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Регионы</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="locationDialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Добавить
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="editedLocation.name"
                            label="Регион"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="locationClose">
                      Отмена
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="locationSave">
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="locationDialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Удалить запись?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="locationCloseDelete"
                      >Нет</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deleteLocationConfirm"
                      >Да</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editLocation(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteLocation(item)"> mdi-delete </v-icon>
          </template>
          <template v-slot:no-data> </template>
        </v-data-table>
      </v-col>
    </v-row>
    <ModalError :show="showError" :header="'Ошибка'" :message="errorMessage" :okClicked="()=>showError=false"/>

  </v-container>
</template>

<script>
import api from "../api/api";
import ModalError from "../components/common/ModalError";
export default {
  name: "LocationPage",
  components: {ModalError},
  data: () => ({
    selectedLocations: [],
    totalLocations: 0,
    locations: [],
    loading: true,
    locationOptions: {},
    locationHeaders: [
      {
        text: "Регион",
        align: "start",
        sortable: false,
        value: "name",
      },
      { value: "actions", sortable: false, width:80 },
    ],
    editedLocation: {
      id: "",
      name: "",
    },
    defaultLocation: {
      name: "",
    },
    locationDialog: false,
    locationDialogDelete: false,
    editedIndex: -1,
    showError: false,
    errorMessage: ""
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Добавить запись"
        : "Редактировать запись";
    },
  },
  watch: {
    locationDialog(val) {
      val || this.locationClose();
    },
    locationDialogDelete(val) {
      val || this.locationCloseDelete();
    },
    locationOptions: {
      handler() {
        this.getLocations();
      },
      deep: true,
    },
  },
  methods: {
    async getLocations() {
      this.loading = true;
      const { page, itemsPerPage } = this.locationOptions;
      const result = await api.admin.locations.getPagedList(page, itemsPerPage);
      this.locations = result.items;
      this.totalLocations = result.pagination.totalItems;
      this.loading = false;
    },

    editLocation(item) {
      this.editedIndex = this.locations.indexOf(item);
      this.editedLocation = Object.assign({}, item);
      this.locationDialog = true;
    },

    deleteLocation(item) {
      this.editedIndex = this.locations.indexOf(item);
      this.editedLocation = Object.assign({}, item);
      this.locationDialogDelete = true;
    },

    async deleteLocationConfirm() {
      const result = await api.admin.locations.deleteLocation(
        this.editedLocation.id
      );
      if (result.success) {
        this.locations.splice(this.editedIndex, 1);
      } else {
        this.errorMessage = result.errorMessage;
        this.showError = true;
      }
      this.locationCloseDelete();
    },

    locationClose() {
      this.locationDialog = false;
      this.$nextTick(() => {
        this.editedLocation = Object.assign({}, this.defaultLocation);
        this.editedIndex = -1;
      });
    },

    locationCloseDelete() {
      this.locationDialogDelete = false;
      this.$nextTick(() => {
        this.editedLocation = Object.assign({}, this.defaultLocation);
        this.editedIndex = -1;
      });
    },

    async locationSave() {
      if (this.editedIndex > -1) {
        const result = await api.admin.locations.editLocation(
          this.editedLocation.id,
          this.editedLocation.name
        );
        if (result.success) {
          Object.assign(this.locations[this.editedIndex], result.location);
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
          return;
        }
      } else {
        const result = await api.admin.locations.createLocation(
          this.editedLocation.name
        );
        if (result.success) {
          this.locations.push(result.location);
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
          return;
        }
      }
      this.locationClose();
    },
  },
};
</script>