<template>
  <v-dialog v-model="show" width="500" persistent>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Разбанить
      </v-card-title>
      <v-card-text>
        <p v-if="error" class="error--text">{{ errorMessage }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="disabledSubmitButton" color="error" @click="unban()">Разбанить</v-btn>
        <v-btn @click="cancel()">Отмена</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api/api";

export default {
  name: "AdvertUnban",
  props: {
    advertId: String,
    successCallback:  {
      type: Function,
      required: true,
    },
    cancelCallback: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    disabledSubmitButton: false,
    error: false,
    errorMessage: '',
  }),
  watch: {
    advertId() {
      this.disabledSubmitButton = false
      this.error = false
      this.errorMessage = ''
    },
  },
  computed: {
    show() {
      return null !== this.advertId
    }
  },
  methods: {
    async unban() {
      this.disabledSubmitButton = true
      const result = await api.admin.adverts.unbanAdvert(this.advertId)
      if (result.success) {
        this.successCallback()
      } else {
        this.error = true
        this.errorMessage = result.errorMessage
        this.disabledSubmitButton = false
      }
    },
    cancel() {
      this.cancelCallback()
    },
  },
}
</script>

<style scoped>

</style>