<template>
  <v-card tile>
    <v-list dense>
      <v-autocomplete class="mx-2"
                      v-model="selectedVariant"
                      :items="variants"
                      item-text="name"
                      item-value="id"
                      label="Варианты"
                      clearable
                      return-object
      ></v-autocomplete>
      <v-list-item-group v-model="selectedVariantIndex" color="primary" style="height: 450px"  class="overflow-y-auto">
        <v-list-item v-for="(item, i) in productVariants" :key="i">
          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-btn
              v-show="selectedVariantIndex >= 0"
              color="error"
              elevation="2"
              v-on:click="showDeleteDialog = true"
      >
        Удалить вариант
      </v-btn>
    </v-list>
    <ModalDialog :show="showDeleteDialog" :header="'Удаление'" :message="'Вы действиетльно хотите удалить вариант?'"
                 :yesClicked="productVariantDelete" :noClicked="()=>showDeleteDialog = false"/>
    <ModalError :show="showError" :header="'Ошибка'" :message="errorMessage" :okClicked="()=>showError=false"/>
  </v-card>
</template>

<script>
  import api from "../../api/api";
  import ModalDialog from "../common/ModalDialog";
  import ModalError from "../common/ModalError";

  export default {
    name: "ProductVariantEditor",
    components: {ModalError, ModalDialog},
    props: {selectedProduct: Object, variants: Array},
    data() {
      return {
        selectedVariant: null,
        productVariants: [],
        selectedVariantIndex: -1,
        showDeleteDialog: false,
        showError: false,
        errorMessage: ""
      }
    },
    watch: {
      selectedVariant() {
        if (this.selectedVariant) this.addVariant();
      },
      selectedProduct() {
        /**@type {Product | null} */
        const product = this.selectedProduct;
        var variants = [];
        if (product) {
          for (const group of product.variantGroups) {
            for (const variant of group.variants) {
              variants.push({
                name: `${group.name} - ${variant.name}`,
                id: variant.id,
              });
            }
          }
        }
        this.productVariants = variants;
        this.selectedFieldIndex = -1;
      }
    },
    methods: {

      async addVariant() {
        const index = this.productVariants.findIndex(
            (p) => p.id === this.selectedVariant.id
        );
        if (index > -1) {
          this.selectedVariant = null;
          return;
        }

        const result = await api.admin.products.addProductVariant(
            this.selectedProduct.id,
            this.selectedVariant.id
        );
        if (result.success) {
          this.productVariants.push(this.selectedVariant);
          this.selectedVariant = null;
          this.selectedVariantIndex = -1;
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
      },

      async productVariantDelete() {
        this.showDeleteDialog = false;
        const variant = this.productVariants[this.selectedVariantIndex];
        const result = await api.admin.products.deleteProductVariant(
            this.selectedProduct.id,
            variant.id
        );
        if (result.success) {
          this.productVariants.splice(this.selectedVariantIndex, 1);
          this.selectedVariantIndex = -1;
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
      },
    }
  }
</script>