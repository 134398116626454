<template>
  <v-container>
    <v-row>
      <!-- Категории -->
      <v-col>
        <v-data-table
                v-model="selectedCategories"
                item-key="id"
                show-select
                :headers="categoryHeaders"
                :items="categories"
                :options.sync="categoryOptions"
                :server-items-length="totalCategories"
                :loading="loading"
                :single-select="true"
                class="elevation-1"
                :footer-props="{'items-per-page-options': [10, 50, 100, 500]}"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Категории</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="categoryDialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                  >
                    Добавить
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="9">
                          <v-text-field
                                  v-model="editedCategory.name"
                                  label="Категория"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-checkbox
                                  v-model="editedCategory.isActive"
                                  label="Активно"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="categoryClose">
                      Отмена
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="categorySave">
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="categoryDialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Удалить запись?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="categoryCloseDelete"
                    >Нет
                    </v-btn
                    >
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteCategoryConfirm"
                    >Да
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.isActive`]="{ item }">
            <span :class="item.isActive ? 'green--text' : 'red--text'">{{item.isActive ? "Да" : "Нет"}}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editCategory(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteCategory(item)"> mdi-delete</v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize"> Reset</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <!-- Группы вариантов -->
      <v-col>
        <v-data-table
                v-model="selectedVariantGroups"
                item-key="id"
                show-select
                :headers="variantGroupHeaders"
                :items="variantGroups"
                :single-select="true"
                class="elevation-1"
                :footer-props="{'items-per-page-options': [10, 50, 100, 500]}"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Группы вариантов</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="variantGroupDialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                          v-show="selectedCategory"
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                  >
                    Добавить
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                                  v-model="editedVariantGroup.name"
                                  label="Группа вариантов"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="variantGroupClose"
                    >
                      Отмена
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="variantGroupSave">
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="variantGroupDialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                  >Удалить запись?
                  </v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="variantGroupCloseDelete"
                    >Нет
                    </v-btn
                    >
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteVariantGroupConfirm"
                    >Да
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editVariantGroup(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteVariantGroup(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-col>

      <!-- Варианты -->
      <v-col>
        <v-data-table
                item-key="id"
                :headers="variantHeaders"
                :items="variants"
                class="elevation-1"
                :footer-props="{'items-per-page-options': [10, 50, 100, 500]}"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Варианты</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="variantDialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                          v-show="selectedVariantGroup"
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                  >
                    Добавить
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                                  v-model="editedVariant.name"
                                  label="Вариант"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="variantClose">
                      Отмена
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="variantSave">
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="variantDialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Удалить запись?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="variantCloseDelete"
                    >Нет
                    </v-btn
                    >
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteVariantConfirm"
                    >Да
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editVariant(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteVariant(item)"> mdi-delete</v-icon>
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <!-- Группы полей -->
      <v-col>
        <v-data-table
                v-model="selectedFieldGroups"
                item-key="id"
                show-select
                :headers="fieldGroupHeaders"
                :items="fieldGroups"
                :single-select="true"
                class="elevation-1"
                :footer-props="{'items-per-page-options': [10, 50, 100, 500]}"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Группы полей</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="fieldGroupDialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                          v-show="selectedCategory"
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                  >
                    Добавить
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                                  v-model="editedFieldGroup.name"
                                  label="Группа полей"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="fieldGroupClose"
                    >
                      Отмена
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="fieldGroupSave">
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="fieldGroupDialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                  >Удалить запись?
                  </v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="fieldGroupCloseDelete"
                    >Нет
                    </v-btn
                    >
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteFieldGroupConfirm"
                    >Да
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.isActive`]="{ item }">
            <span>{{item.isActive ? "Да" : "Нет"}}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editFieldGroup(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteFieldGroup(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-col>

      <!-- Поля -->
      <v-col>
        <v-data-table
                item-key="id"
                :headers="fieldHeaders"
                :items="fields"
                class="elevation-1"
                :footer-props="{'items-per-page-options': [10, 50, 100, 500]}"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Поля</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="fieldDialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                          v-show="selectedFieldGroup"
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                  >
                    Добавить
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                                  v-model="editedField.name"
                                  label="Поле"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="fieldClose">
                      Отмена
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="fieldSave">
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="fieldDialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Удалить запись?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="fieldCloseDelete"
                    >Нет
                    </v-btn
                    >
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteFieldConfirm"
                    >Да
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editField(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteField(item)"> mdi-delete</v-icon>
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-col>
    </v-row>
    <ModalError :show="showError" :header="'Ошибка'" :message="errorMessage" :okClicked="()=>showError=false"/>

  </v-container>
</template>

<script>
  import api from "../api/api";
  import ModalError from "../components/common/ModalError";

  export default {
    name: "CategoryPage",
    components: {ModalError},
    data: () => ({
      //-----------Categories-----------
      selectedCategories: [],
      totalCategories: 0,
      categories: [],
      loading: true,
      categoryOptions: {},
      categoryHeaders: [
        {
          text: "Категория",
          align: "start",
          sortable: false,
          value: "name",
        },
        {text: "Активно", value: "isActive", sortable: false, width: 60},
        {value: "actions", sortable: false, width: 80},
      ],
      selectedCategory: null,
      editedCategory: {
        id: "",
        name: "",
        isActive: false,
      },
      defaultCategory: {
        name: "",
        isActive: true,
      },
      categoryDialog: false,
      categoryDialogDelete: false,

      //-----------VariantGroups-----------
      variantGroupHeaders: [
        {
          text: "Группа вариантов",
          align: "start",
          sortable: false,
          value: "name",
        },
        {value: "actions", sortable: false, width: 80},
      ],
      variantGroups: [],
      editedVariantGroup: {
        id: "",
        name: "",
      },
      defaultVariantGroup: {
        name: "",
      },
      variantGroupDialog: false,
      variantGroupDialogDelete: false,
      selectedVariantGroups: [],
      selectedVariantGroup: null,

      //-----------Variant-----------
      variantHeaders: [
        {
          text: "Вариант",
          align: "start",
          sortable: false,
          value: "name",
        },
        {value: "actions", sortable: false, width: 80},
      ],
      variants: [],
      editedVariant: {
        id: "",
        name: "",
      },
      defaultVariant: {
        name: "",
      },
      variantDialog: false,
      variantDialogDelete: false,
      //-----------------------------------

      //-----------FieldGroups-----------
      fieldGroupHeaders: [
        {
          text: "Группа полей",
          align: "start",
          sortable: false,
          value: "name",
        },
        {value: "actions", sortable: false, width: 80},
      ],
      fieldGroups: [],
      editedFieldGroup: {
        id: "",
        name: "",
      },
      defaultFieldGroup: {
        name: "",
      },
      fieldGroupDialog: false,
      fieldGroupDialogDelete: false,
      selectedFieldGroups: [],
      selectedFieldGroup: null,
      //----------------------------------

      //-----------Field-----------
      fieldHeaders: [
        {
          text: "Поле",
          align: "start",
          sortable: false,
          value: "name",
        },
        {value: "actions", sortable: false, width: 80},
      ],
      fields: [],
      editedField: {
        id: "",
        name: "",
      },
      defaultField: {
        name: "",
      },
      fieldDialog: false,
      fieldDialogDelete: false,
      //-----------------------------------

      editedIndex: -1,
      showError: false,
      errorMessage: ""
    }),
    computed: {
      formTitle() {
        return this.editedIndex === -1
            ? "Добавить запись"
            : "Редактировать запись";
      },
    },
    watch: {
      categoryDialog(val) {
        val || this.categoryClose();
      },
      categoryDialogDelete(val) {
        val || this.categoryCloseDelete();
      },
      categoryOptions: {
        handler() {
          this.getCategories();
        },
        deep: true,
      },
      selectedCategories(rows) {
        this.selectedCategory = rows && rows.length == 1 ? rows[0] : null;
        this.variantGroups = this.selectedCategory
            ? this.selectedCategory.variantGroups
            : [];
        this.selectedVariantGroups = [];

        this.fieldGroups = this.selectedCategory
            ? this.selectedCategory.fieldGroups
            : [];
        this.selectedFieldGroups = [];
      },
      //variantGroup
      variantGroupDialog(val) {
        val || this.variantGroupClose();
      },
      variantGroupDialogDelete(val) {
        val || this.variantGroupCloseDelete();
      },
      selectedVariantGroups(rows) {
        this.selectedVariantGroup = rows && rows.length == 1 ? rows[0] : null;
        this.variants = this.selectedVariantGroup
            ? this.selectedVariantGroup.variants
            : [];
      },
      //variant
      variantDialog(val) {
        val || this.variantClose();
      },
      variantDialogDelete(val) {
        val || this.variantCloseDelete();
      },
      //fieldGroup
      fieldGroupDialog(val) {
        val || this.fieldGroupClose();
      },
      fieldGroupDialogDelete(val) {
        val || this.fieldGroupCloseDelete();
      },
      selectedFieldGroups(rows) {
        this.selectedFieldGroup = rows && rows.length == 1 ? rows[0] : null;
        this.fields = this.selectedFieldGroup
            ? this.selectedFieldGroup.fields
            : [];
      },
      //field
      fieldDialog(val) {
        val || this.fieldClose();
      },
      fieldDialogDelete(val) {
        val || this.fieldCloseDelete();
      },
    },
    methods: {
      async getCategories() {
        this.loading = true;
        const {page, itemsPerPage} = this.categoryOptions;
        const result = await api.admin.categories.getPagedList(
            page,
            itemsPerPage
        );
        this.categories = result.items;
        this.totalCategories = result.pagination.totalItems;
        this.loading = false;
      },

      editCategory(item) {
        this.editedIndex = this.categories.indexOf(item);
        this.editedCategory = Object.assign({}, item);
        this.categoryDialog = true;
      },

      deleteCategory(item) {
        this.editedIndex = this.categories.indexOf(item);
        this.editedCategory = Object.assign({}, item);
        this.categoryDialogDelete = true;
      },

      async deleteCategoryConfirm() {
        const result = await api.admin.categories.deleteCategory(
            this.editedCategory.id
        );
        if (result.success) {
          this.categories.splice(this.editedIndex, 1);
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
        this.categoryCloseDelete();
      },

      categoryClose() {
        this.categoryDialog = false;
        this.$nextTick(() => {
          this.editedCategory = Object.assign({}, this.defaultCategory);
          this.editedIndex = -1;
        });
      },

      categoryCloseDelete() {
        this.categoryDialogDelete = false;
        this.$nextTick(() => {
          this.editedCategory = Object.assign({}, this.defaultCategory);
          this.editedIndex = -1;
        });
      },

      async categorySave() {
        if (this.editedIndex > -1) {
          const result = await api.admin.categories.editCategory(
              this.editedCategory.id,
              this.editedCategory.name,
              this.editedCategory.isActive
          );
          if (result.success) {
            Object.assign(this.categories[this.editedIndex], result.category);
          } else {
            this.errorMessage = result.errorMessage;
            this.showError = true;
            return;
          }
        } else {
          const result = await api.admin.categories.createCategory(
              this.editedCategory.name,
              this.editedCategory.isActive
          );
          if (result.success) {
            this.categories.push(result.category);
          } else {
            this.errorMessage = result.errorMessage;
            this.showError = true;
            return;
          }
        }
        this.categoryClose();
      },

      //------VariantGroups-------
      editVariantGroup(item) {
        this.editedIndex = this.variantGroups.indexOf(item);
        this.editedVariantGroup = Object.assign({}, item);
        this.variantGroupDialog = true;
      },

      deleteVariantGroup(item) {
        this.editedIndex = this.variantGroups.indexOf(item);
        this.editedVariantGroup = Object.assign({}, item);
        this.variantGroupDialogDelete = true;
      },

      async deleteVariantGroupConfirm() {
        const result = await api.admin.specs.deleteVariantGroup(
            this.editedVariantGroup.id
        );
        if (result.success) {
          this.variantGroups.splice(this.editedIndex, 1);
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
        this.variantGroupCloseDelete();
      },

      variantGroupClose() {
        this.variantGroupDialog = false;
        this.$nextTick(() => {
          this.editedVariantGroup = Object.assign({}, this.defaultVariantGroup);
          this.editedIndex = -1;
        });
      },

      variantGroupCloseDelete() {
        this.variantGroupDialogDelete = false;
        this.$nextTick(() => {
          this.editedVariantGroup = Object.assign({}, this.defaultVariantGroup);
          this.editedIndex = -1;
        });
      },

      async variantGroupSave() {
        if (this.editedIndex > -1) {
          const result = await api.admin.specs.editVariantGroups(
              this.editedVariantGroup.id,
              this.editedVariantGroup.name
          );
          if (result.success) {
            Object.assign(
                this.variantGroups[this.editedIndex],
                result.varianrGroup
            );
          } else {
            this.errorMessage = result.errorMessage;
            this.showError = true;
            return;
          }
        } else {
          const result = await api.admin.categories.createVariantGroups(
              this.selectedCategory.id,
              this.editedVariantGroup.name
          );
          if (result.success) {
            this.variantGroups.push(result.variantGroup);
          } else {
            this.errorMessage = result.errorMessage;
            this.showError = true;
            return;
          }
        }
        this.variantGroupClose();
      },

      //------Variant-------
      editVariant(item) {
        this.editedIndex = this.variants.indexOf(item);
        this.editedVariant = Object.assign({}, item);
        this.variantDialog = true;
      },

      deleteVariant(item) {
        this.editedIndex = this.variants.indexOf(item);
        this.editedVariant = Object.assign({}, item);
        this.variantDialogDelete = true;
      },

      async deleteVariantConfirm() {
        const result = await api.admin.specs.deleteVariant(this.editedVariant.id);
        if (result.success) {
          this.variants.splice(this.editedIndex, 1);
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
        this.variantCloseDelete();
      },

      variantClose() {
        this.variantDialog = false;
        this.$nextTick(() => {
          this.editedVariant = Object.assign({}, this.defaultVariant);
          this.editedIndex = -1;
        });
      },

      variantCloseDelete() {
        this.variantDialogDelete = false;
        this.$nextTick(() => {
          this.editedVariant = Object.assign({}, this.defaultVariant);
          this.editedIndex = -1;
        });
      },

      async variantSave() {
        if (this.editedIndex > -1) {
          const result = await api.admin.specs.editVariant(
              this.editedVariant.id,
              this.editedVariant.name
          );
          if (result.success) {
            Object.assign(this.variants[this.editedIndex], result.variant);
          } else {
            this.errorMessage = result.errorMessage;
            this.showError = true;
            return;
          }
        } else {
          const result = await api.admin.specs.createVariant(
              this.selectedVariantGroup.id,
              this.editedVariant.name
          );
          if (result.success) {
            this.variants.push(result.variant);
          } else {
            this.errorMessage = result.errorMessage;
            this.showError = true;
            return;
          }
        }
        this.variantClose();
      },

      //------FieldGroup-------
      editFieldGroup(item) {
        this.editedIndex = this.fieldGroups.indexOf(item);
        this.editedFieldGroup = Object.assign({}, item);
        this.fieldGroupDialog = true;
      },

      deleteFieldGroup(item) {
        this.editedIndex = this.fieldGroups.indexOf(item);
        this.editedFieldGroup = Object.assign({}, item);
        this.fieldGroupDialogDelete = true;
      },

      async deleteFieldGroupConfirm() {
        const result = await api.admin.specs.deleteFieldGroup(
            this.editedFieldGroup.id
        );
        if (result.success) {
          this.fieldGroups.splice(this.editedIndex, 1);
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
        this.fieldGroupCloseDelete();
      },

      fieldGroupClose() {
        this.fieldGroupDialog = false;
        this.$nextTick(() => {
          this.editedFieldGroup = Object.assign({}, this.defaultFieldGroup);
          this.editedIndex = -1;
        });
      },

      fieldGroupCloseDelete() {
        this.fieldGroupDialogDelete = false;
        this.$nextTick(() => {
          this.editedFieldGroup = Object.assign({}, this.defaultFieldGroup);
          this.editedIndex = -1;
        });
      },

      async fieldGroupSave() {
        if (this.editedIndex > -1) {
          const result = await api.admin.specs.editFieldGroups(
              this.editedFieldGroup.id,
              this.editedFieldGroup.name
          );
          if (result.success) {
            Object.assign(
                this.fieldGroups[this.editedIndex],
                result.fieldGroup
            );
          } else {
            this.errorMessage = result.errorMessage;
            this.showError = true;
            return;
          }
        } else {
          const result = await api.admin.categories.createFieldGroups(
              this.selectedCategory.id,
              this.editedFieldGroup.name
          );
          if (result.success) {
            this.fieldGroups.push(result.fieldGroup);
          } else {
            this.errorMessage = result.errorMessage;
            this.showError = true;
            return;
          }
        }
        this.fieldGroupClose();
      },

      //------Field-------
      editField(item) {
        this.editedIndex = this.fields.indexOf(item);
        this.editedField = Object.assign({}, item);
        this.fieldDialog = true;
      },

      deleteField(item) {
        this.editedIndex = this.fields.indexOf(item);
        this.editedField = Object.assign({}, item);
        this.fieldDialogDelete = true;
      },

      async deleteFieldConfirm() {
        const result = await api.admin.specs.deleteField(this.editedField.id);
        if (result.success) {
          this.fields.splice(this.editedIndex, 1);
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
        this.fieldCloseDelete();
      },

      fieldClose() {
        this.fieldDialog = false;
        this.$nextTick(() => {
          this.editedField = Object.assign({}, this.defaultField);
          this.editedIndex = -1;
        });
      },

      fieldCloseDelete() {
        this.fieldDialogDelete = false;
        this.$nextTick(() => {
          this.editedField = Object.assign({}, this.defaultField);
          this.editedIndex = -1;
        });
      },

      async fieldSave() {
        if (this.editedIndex > -1) {
          const result = await api.admin.specs.editField(
              this.editedField.id,
              this.editedField.name
          );
          if (result.success) {
            Object.assign(this.fields[this.editedIndex], result.field);
          } else {
            this.errorMessage = result.errorMessage;
            this.showError = true;
            return;
          }
        } else {
          const result = await api.admin.specs.createField(
              this.selectedFieldGroup.id,
              this.editedField.name
          );
          if (result.success) {
            this.fields.push(result.field);
          } else {
            this.errorMessage = result.errorMessage;
            this.showError = true;
            return;
          }
        }
        this.fieldClose();
      },
    },
  };
</script>
