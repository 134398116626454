<template>
  <v-card elevation="1"  class="mx-auto mt-5" max-width="400">
    <v-card-text class="text--primary py-0">
      <v-text-field
              label="Телефон"
              required
              v-model="phone"
      ></v-text-field>
    </v-card-text>

    <div class="text-center pb-3">
      <v-btn elevation="2"
              rounded
              class="px-5"
              color="primary"
              @click="sendOTP"
      >Установить ОТП
      </v-btn>
    </div>
  </v-card>
</template>

<script>

  import api from "../api/api";

  export default {
    name: "OtpPage",
    methods: {
      async sendOTP() {
        const result = await api.security.phone.setOTP(this.phone);

        if (result.success) {
          alert("ОТП успешно установлен");
          this.phone = "";
        } else {
          this.errorMessage = result.errorMessage;
        }
      },
    },
    data: () => ({
      phone: "",
      errorMessage: null,
    })
  }
</script>

<style scoped>

</style>