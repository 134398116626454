<template>
  <div class="text-center">
    <v-dialog
            v-model="show"
            width="880"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Фото загруженные пользователем
        </v-card-title>

        <v-card-text>
          <v-row no-gutters>
          <v-col cols="6" md="3" v-for="(item) in images" :key="item.thumbUrl">
              <v-img contain
                      max-height="200"
                      max-width="200"
                      :src="item.thumbUrl"
              ></v-img>
          </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
                  color="primary"
                  text
                  @click="okClick">
            Ок
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: "AdvertImagesDialog",
    props: {show: Boolean, images: Array, okClicked: Function},
    methods: {
      okClick() {
        if (this.okClicked)
          this.okClicked();
      }
    }
  }
</script>