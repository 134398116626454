var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"text-center"},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","headers":_vm.usersHeaders,"items":_vm.users,"options":_vm.usersOptions,"server-items-length":_vm.totalUsers,"loading":_vm.loading,"single-select":true,"footer-props":{'items-per-page-options': [10, 50, 100, 500]}},on:{"update:options":function($event){_vm.usersOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Пользователи")]),_c('v-spacer')],1)]},proxy:true},{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.image)?_c('img',{attrs:{"src":item.image ? item.image.thumbUrl : '',"width":"80","height":"80"}}):_vm._e()]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":item.isActive ? 'red' : 'blue'},on:{"click":function($event){return _vm.banUserClick(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.isActive ? "mdi-account-cancel-outline " : "mdi-account-check-outline"))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.isActive ? "Забанить пользователя" : "Разбанить пользователя"))])])]}},{key:"no-data",fn:function(){return undefined},proxy:true}],null,true)})],1)],1),_c('ModalError',{attrs:{"show":_vm.showError,"header":'Ошибка',"message":_vm.errorMessage,"okClicked":function (){ return _vm.showError=false; }}}),_c('ModalDialog',{attrs:{"show":_vm.showBanDialog,"header":'Блокировка пользователя',"message":'Вы действиетльно хотите забанить полльзователя?',"yesClicked":_vm.banUserConfirm,"noClicked":function (){ return _vm.showBanDialog = false; }}}),_c('ModalDialog',{attrs:{"show":_vm.showUnbanDialog,"header":'Блокировка пользователя',"message":'Вы действиетльно хотите разбанить полльзователя?',"yesClicked":_vm.unbanUserConfirm,"noClicked":function (){ return _vm.showUnbanDialog = false; }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }