var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('AdvertFilter',{attrs:{"brands":_vm.brands,"categories":_vm.categories,"filter-changed":_vm.filterChanged}})],1)],1),_c('v-row',{staticClass:"text-center"},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","headers":_vm.advertsHeaders,"items":_vm.adverts,"options":_vm.advertsOptions,"server-items-length":_vm.totalAdverts,"loading":_vm.loading,"single-select":true,"footer-props":{'items-per-page-options': [10, 50, 100, 500]}},on:{"update:options":function($event){_vm.advertsOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Объявления")]),_c('v-spacer')],1)]},proxy:true},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('router-link',{attrs:{"to":'/adverts/' + item.id}},[_vm._v(_vm._s(item.product.brand.name)+" "+_vm._s(item.product.name))])],1)]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.user.phone))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.description)?_c('v-btn',{staticStyle:{"padding":"0","margin":"0"},attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.showDescription(item)}}},[_vm._v(" Есть ")]):_c('v-btn',{staticStyle:{"padding":"0","margin":"0"},attrs:{"text":"","color":"primary","disabled":""}},[_vm._v(" Нет ")])]}},{key:"item.images",fn:function(ref){
var item = ref.item;
return [(item.images && item.images.length > 0)?_c('v-btn',{staticStyle:{"padding":"0","margin":"0"},attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.showImages(item)}}},[_vm._v(" Есть ")]):_c('v-btn',{staticStyle:{"padding":"0","margin":"0"},attrs:{"text":"","color":"primary","disabled":""}},[_vm._v(" Нет ")])]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":item.isApproved ? 'red' : 'blue'},on:{"click":function($event){return _vm.banAdvertClick(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.isApproved ? "mdi-cancel" : "mdi-check"))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.isApproved ? "Забанить объявление" : "Разбанить объявление"))]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(item.isApproved === false),expression:"item.isApproved === false"}]},[_vm._v(_vm._s(item.banReason))])])]}},{key:"no-data",fn:function(){return undefined},proxy:true}],null,true)})],1)],1),_c('ModalError',{attrs:{"show":_vm.showError,"header":"Ошибка","message":_vm.errorMessage,"okClicked":function (){ return _vm.showError=false; }}}),_c('AdvertBanDialog',{attrs:{"show":_vm.showBanDialog,"header":'Блокировка объявления',"message":"Вы действиетльно хотите забанить объявление?","yesClicked":_vm.banAdvertConfirm,"noClicked":function (){ return _vm.showBanDialog = false; }}}),_c('ModalDialog',{attrs:{"show":_vm.showUnbanDialog,"header":'Блокировка объявления',"message":"Вы действиетльно хотите разбанить объявление?","yesClicked":_vm.unbanAdvertConfirm,"noClicked":function (){ return _vm.showUnbanDialog = false; }}}),_c('AdvertDescriptionDialog',{attrs:{"show":_vm.showDescriptionDialog,"message":_vm.currentAdvertDescription,"okClicked":function (){ return _vm.showDescriptionDialog=false; }}}),_c('AdvertImagesDialog',{attrs:{"show":_vm.showImagesDialog,"images":_vm.currentAdvertImages,"okClicked":function (){ return _vm.showImagesDialog=false; }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }