import HttpClient, {BaseResult} from "../httpClient";

export class Translations {
  /**
   * @param {number} page № страницы
   * @param {number} perPage Кол-во результатов на странице
   * @returns {Promise<BaseResult>}
   */
  async getPagedList(page, perPage) {
    const result = await HttpClient.Get(`/v1/admin/translations/kg?page=${page}&perPage=${perPage}`);
    if (result.data) {
      result.data.success = true;
      return result.data;
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} message Текст ru
   * @param {string} translation Перевод
   * @returns {Promise<BaseResult>}
   */
  async createTranslation(message, translation) {
    const json = { message, translation };

    const result = await HttpClient.PostJson(`/v1/admin/translations/kg`, json);
    if (result.data) {
      result.data.success = true;
      return result.data;
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} translationId
   * @param {string} message Текст ru
   * @param {string} translation Перевод
   * @returns {Promise<BaseResult>}
   */
  async editTranslation(translationId,message, translation) {
    const json = { message, translation };

    const result = await HttpClient.PutJson(`/v1/admin/translations/${translationId}`, json);
    if (result.data) {
      result.data.success = true;
      return result.data;
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} translationId
   * @returns {Promise<BaseResult>}
   */
  async deleteTranslation(translationId) {
    const result = await HttpClient.Delete(`/v1/admin/translations/${translationId}`);
    if (result.data) {
      result.data.success = true;
      return result.data;
    }
    return new BaseResult(false, result.error.message);
  }
}