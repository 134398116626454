import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api/api';

Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
        authorized: false,
        userName: "",
        userPhoto: ""
    },
    mutations: {
        async initialiseStore(state) {
            const token = localStorage.getItem('token');

            if (token) {
                api.token = token;
                state.authorized = true;
                const profile = await api.user.getProfile();
                if (profile.success) {
                    state.userName = profile.userProfile.name;
                    state.userPhoto = profile.userProfile.image ? "https://api.fm.kg" + profile.userProfile.image.thumbPath : null;
                }else {
                    api.token = null;
                    state.authorized = false;
                    localStorage.removeItem('token')
                }
            }
        },

        async authorization(state) {
            state.authorized = true;
            const profile = await api.user.getProfile();
            if(profile.success)
            {
                state.userName = profile.userProfile.name;
                state.userPhoto = profile.userProfile.image ? "https://api.fm.kg" + profile.userProfile.image.thumbPath : null;
            }
        },
        logout(state) {
            state.authorized = false;
            api.token = null;
            localStorage.removeItem('token');
        }
    }
});

export default store;