<template>
  <div class="text-center">
    <v-dialog
            v-model="show"
            width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{header}}
        </v-card-title>

        <v-card-text>
          {{message}}
        </v-card-text>

        <v-divider></v-divider>

        <v-textarea
                solo
                name="input-7-4"
                label="Причина бана"
                v-model="reason"
        ></v-textarea>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="reason === null || reason.length === 0"
                  color="error"
                  text
                  @click="yesClick">
            Да
          </v-btn>
          <v-btn
                  color="primary"
                  text
                  @click="noClick">
            Нет
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: "AdvertBanDialog",
    props: {show: Boolean, header: String, message: String, yesClicked: Function, noClicked: Function},
    data() {
      return {
        reason: ""
      }
    },
    methods: {
      yesClick() {
        if (this.yesClicked)
          this.yesClicked(this.reason);
      },
      noClick() {
        console.log(this.reason);
        if (this.noClicked)
          this.noClicked();
      }
    }
  }
</script>