import { Pagination } from "../commonStucts";
import HttpClient, { BaseResult } from "../httpClient";

export class ComplaintReason {
  /**@type {string} */
  id;
  /**@type {string} */
  name;

  /**
   * @param {string} id
   * @param {string} name
   */
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  /**
   * @param {any} json
   * @returns {ComplaintReason|null}
   */
  static ParseJson(json) {
    return json ? new ComplaintReason(json.id, json.name) : null;
  }
}

export class ComplaintReasonPagedList {
  /**@type {ComplaintReason[]} */
  items;
  /**@type {Pagination} */
  pagination;

  /**
   * @param {ComplaintReason[]} items
   * @param {Pagination} pagination
   */
  constructor(items, pagination) {
    this.items = items;
    this.pagination = pagination;
  }

  /**
   * @param {any} json
   * @returns {ComplaintReasonPagedList|null}
   */
  static ParseJson(json) {
    if (json) {
      const items = json.items ? json.items.map(v => ComplaintReason.ParseJson(v)) : [];
      const pagination = Pagination.ParseJson(json.pagination);
      return new ComplaintReasonPagedList(items, pagination);
    }
    return null;
  }
}

export class CreateOrEditComplaintReasonResult extends BaseResult {
  /**@type {ComplaintReason} */
  complaintReason;

  constructor(success, errorMessage, complaintReason) {
    super(success, errorMessage);
    this.complaintReason = complaintReason;
  }
}

export class ComplaintReasons {
  /**
 * @param {number} page № страницы
 * @param {number} perPage Кол-во результатов на странице
 * @returns {Promise<ComplaintReasonPagedList>}
 */
  async getPagedList(page, perPage) {
    const result = await HttpClient.Get(`/v1/admin/complaints/reasons?page=${page}&perPage=${perPage}`);
    if (result.data) {
      return ComplaintReasonPagedList.ParseJson(result.data);
    }
    return new BaseResult(false, result.error.message);
  }

  async getComplaints(page, perPage) {
    const result = await HttpClient.Get(`/v1/admin/complaints?page=${page}&perPage=${perPage}`);
    if (result.data) {
      result.success = true;
      return result.data;
    }
    return new BaseResult(false, result.error.message);
  }

  async setComplaintIsRead(complaintId) {
    const result = await HttpClient.Post(`/v1/admin/complaints/${complaintId}`);
    if (result.data) {
      return new BaseResult(result.data.success, '');
    }
    return new BaseResult(false, result.error.message);
  }

  /**
 * @param {string} name
 * @returns {Promise<CreateOrEditComplaintReasonResult>}
 */
  async createComplaintReason(name) {
    const json = { name };

    const result = await HttpClient.PostJson(`/v1/admin/complaints/reasons`, json);
    if (result.data) {
      return new CreateOrEditComplaintReasonResult(true, null, ComplaintReason.ParseJson(result.data));
    }
    return new BaseResult(false, result.error.message);
  }

  /**
* @param {string} complaintReasonId
* @param {string} name
* @returns {Promise<CreateOrEditComplaintReasonResult>}
*/
  async editComplaintReason(complaintReasonId, name) {
    const json = { name };

    const result = await HttpClient.PutJson(`/v1/admin/complaints/reasons/${complaintReasonId}`, json);
    if (result.data) {
      return new CreateOrEditComplaintReasonResult(true, null, ComplaintReason.ParseJson(result.data));
    }
    return new BaseResult(false, result.error.message);
  }

  /**
* @param {string} complaintReasonId
* @returns {Promise<BaseResult>}
*/
  async deleteComplaintReason(complaintReasonId) {
    const result = await HttpClient.Delete(`/v1/admin/complaints/reasons/${complaintReasonId}`);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }
}