<template>
  <v-card>
    <p class="text-center">
      <span class="text-h6">Фотографии</span>
    </p>
    <v-row style="height: 710px">
      <v-col cols="1" xl="1" lg="2" md="2" sm="3" xs="3">
        <v-list-item-group v-model="selectedImageIndex" color="primary" class="imageList">
          <v-list-item v-for="(item, i) in images" :key="i">
            <v-list-item-content>
              <v-img
                      contain
                      max-height="180"
                      max-width="150"
                      :src="baseUrl + item.thumbPath"
              ></v-img>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-col>

      <v-col v-show="selectedImageUrl!== null && selectedImageUrl.length > 0" cols="11" xl="11" lg="10" md="10" sm="9"
             xs="9">
        <div class="productSelectedPhoto">
          <v-img contain height="600" :src="selectedImageUrl"></v-img>

          <div class="productColors">
            <p>Цвет фото</p>
            <v-checkbox v-for="(item) in productColors" class="shrink mr-0 mt-0"
                        v-model="selectedColors"
                        v-bind:item="item"
                        v-bind:key="item.id"
                        v-bind:label="item.name"
                        v-bind:value="item.name"
                        :disabled="colorSave"
                        name></v-checkbox>
          </div>
        </div>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="5">
        <v-file-input class="ml-3"
                      accept="image/png, image/jpeg, image/bmp"
                      placeholder="Загрузить фотографию"
                      prepend-icon="mdi-camera"
                      label="Фото"
                      show-size
                      v-model="chosenFile"
                      :append-outer-icon="chosenFile ? 'mdi-file-upload-outline' : ''"
                      @click:append-outer="uploadImage"
        ></v-file-input>
      </v-col>
      <v-col cols="7" class="text-right">
        <v-btn class="mr-3"
               v-show="selectedImageIndex > -1"
               color="error"
               elevation="2"
               v-on:click="showDeleteDialog = true"
        >
          Удалить фото
        </v-btn>
      </v-col>
    </v-row>
    <ModalError :show="showError" :header="'Ошибка'" :message="errorMessage" :okClicked="()=>showError=false"/>
    <ModalDialog :show="showDeleteDialog" :header="'Удаление'" :message="'Вы действиетльно хотите удалить фото?'"
                 :yesClicked="imageDelete" :noClicked="()=>showDeleteDialog = false"/>
  </v-card>
</template>

<script>
  import api from "../../api/api";
  import ModalError from "../common/ModalError";
  import HttpClient from "../../api/httpClient";
  import ModalDialog from "../common/ModalDialog";

  export default {
    name: "ProductImagesEditor",
    components: {ModalDialog, ModalError},
    props: {selectedProduct: Object},
    created() {
      this.baseUrl = HttpClient.BaseUrl;
    },
    data() {
      return {
        baseUrl: "",
        chosenFile: null,
        showError: false,
        errorMessage: "",
        images: [],
        selectedImage: null,
        selectedImageIndex: -1,
        selectedImageUrl: "",
        showDeleteDialog: false,
        selectedColors: [],
        productColors: [],
        colorSave: false
      }
    },
    watch: {
      selectedProduct() {
        this.images = this.selectedProduct ? this.selectedProduct.images : [];
        this.productColors = this.selectedProduct ? this.selectedProduct.colors : [];
        this.selectedImageIndex = this.images.length > 0 ? 0 : -1;
      },
      selectedImageIndex() {
        this.selectedImageUrl = this.selectedImageIndex > -1 ? HttpClient.BaseUrl + this.selectedProduct.images[this.selectedImageIndex].imagePath : "";
        this.selectedImage = this.selectedImageIndex > -1 ? this.selectedProduct.images[this.selectedImageIndex] : null;
        this.selectedColors = this.selectedImage ? this.selectedImage.colors.map(c => c.name) : [];
      },
      selectedColors() {
        if (this.productColors.length === 0 || !this.selectedImage)
          return;

        for (let color of this.selectedColors) {
          if (this.selectedImage.colors.some(value => value.name === color))
            continue;

          var newColor = this.productColors.find(value => value.name === color);
          this.addColor(newColor, this.selectedImage.id);
          return;
        }

        for (let color of this.selectedImage.colors) {
          if (this.selectedColors.some(value => value === color.name))
            continue;

          this.deleteColor(color, this.selectedImage.id);
          return;
        }
      }
    },
    methods: {
      async uploadImage() {
        if (!this.chosenFile)
          return;
        const result = await api.admin.products.addProductImage(this.selectedProduct.id, this.chosenFile);
        if (result.success) {
          this.selectedProduct.images.push(result);
          this.images = this.selectedProduct.images;
          this.selectedImageIndex = this.images.length - 1;
          this.chosenFile = null;
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
      },
      async imageDelete() {
        this.showDeleteDialog = false;
        const image = this.selectedProduct.images[this.selectedImageIndex];
        const result = await api.admin.products.deleteProductImage(image.id);
        if (result.success) {
          this.selectedProduct.images.splice(this.selectedImageIndex, 1);
          this.images = this.selectedProduct.images;
          this.selectedImageIndex = this.images.length > 0 ? 0 : -1;
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
      },
      async addColor(color, imageId) {
        this.colorSave = true;
        const result = await api.admin.products.addProductImageColor(imageId, color.id);
        this.colorSave = false;
        if (result.success) {
          this.selectedImage.colors.push(color);
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
      },
      async deleteColor(color, imageId) {
        this.colorSave = true;
        const result = await api.admin.products.deleteProductImageColor(imageId, color.id);
        this.colorSave = false;
        if (result.success) {
          const index = this.selectedImage.colors.findIndex(c => c.name === color.name);
          this.selectedImage.colors.splice(index, 1);
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
      }
    }
  }
</script>

<style scoped>
  .imageList {
    height: 700px;
    overflow-y: auto
  }

  .productColors {
    position: absolute;
    top: 0px;
    right: 10px;
    background-color: rgba(245, 245, 245, 0.80);
    width: 170px;
  }

  .productSelectedPhoto {
    position: relative;
  }
</style>