<template>
  <v-container>
    <p v-if="complaints.length" class="text-h6">Жалобы</p>
    <v-card v-for="complaint in complaints" :key="complaint.id" class="my-2" elevation="0">
      <v-card-text>
        <v-row>
          <v-col cols="5"><UserCard :user="complaint.user" /></v-col>
          <v-col>
            <div class="text-overline">
              <v-btn x-small fab outlined class="mr-1" color="success" @click="selectedComplaintId = complaint.id"><v-icon>mdi-check</v-icon></v-btn>
              {{ complaint.datetime }}
            </div>
            <div class="my-2">
              <router-link :to="'/adverts/' + complaint.advert.id">{{ complaint.advert.product.brand.name }} {{ complaint.advert.product.name }}</router-link>
            </div>
            <p class="text-subtitle-2">{{ complaint.reason.name }}</p>
            <p style="white-space: break-spaces;">{{ complaint.description }}</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showComplainDialog" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Прочитано
        </v-card-title>
        <v-card-text>
          <p v-if="error" class="error--text">{{ errorMessage }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="disabledSubmitButton" color="success" @click="setIsRead()">Прочитано</v-btn>
          <v-btn @click="cancel()">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from "@/api/api";
import UserCard from "@/components/user/UserCard";

export default {
  name: "ComplaintsList",
  components: {UserCard},
  data: () => ({
    complaints: [],
    selectedComplaintId: null,
    error: false,
    errorMessage: '',
    disabledSubmitButton: false,
  }),
  created: function () {
    this.fetchComplaints()
  },
  watch: {
  },
  computed: {
    showComplainDialog() {
      return null !== this.selectedComplaintId
    }
  },
  methods: {
    async fetchComplaints() {
      const result = await api.admin.complaintReasons.getComplaints(1, 100);
      this.complaints = result.items;
    },
    async setIsRead() {
      this.disabledSubmitButton = true
      const result = await api.admin.complaintReasons.setComplaintIsRead(this.selectedComplaintId)
      if (result.success) {
        this.fetchComplaints()
        this.cancel()
      } else {
        this.error = true
        this.errorMessage = result.errorMessage
        this.disabledSubmitButton = false
      }
    },
    cancel() {
      this.selectedComplaintId = null
      this.disabledSubmitButton = false
      this.error = false
      this.errorMessage = ''
    },
  },
};
</script>