import HttpClient, {BaseResult} from "../httpClient";

export class UserInfo {
  /**@type {string} */
  id;
  /**@type {null|string} */
  phone;
  /**@type {null|string} */
  email;
  /**@type {null|string} */
  name;
  /**@type {string} */
  createdAt; /* Дата регистрации */
  /**@type {boolean} */
  isActive;
  /**@type {number} */
  totalAdverts; /* Кол-во объявлений */
}

export class UsersPagedList {
  /**@type {UserInfo[]} */
  items;
  /**@type {Pagination} */
  pagination;
}

export class Users {
  /**
   * @param {number} page № страницы
   * @param {number} perPage Кол-во результатов на странице
   * @returns {Promise<UsersPagedList>}
   */
  async getPagedList(page, perPage) {
    const result = await HttpClient.Get(`/v1/admin/users?page=${page}&perPage=${perPage}`);
    if (result.data) {
      result.data.success = true;
      return result.data;
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} userId
   * @returns {Promise<BaseResult>}
   */
  async banUser(userId) {
    const result = await HttpClient.Post(`/v1/admin/users/${userId}/ban`);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} userId
   * @returns {Promise<BaseResult>}
   */
  async unbanUser(userId) {
    const result = await HttpClient.Post(`/v1/admin/users/${userId}/unban`);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }
}