import { ImageInfo, Pagination } from "../commonStucts";
import HttpClient, { BaseResult } from "../httpClient";

export class Variant {
  /**@type {string} */
  id;
  /**@type {string} */
  name;

  /**
   * @param {string} id 
   * @param {string} name 
   */
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  /**
   * @param {any} json
   * @returns {Variant|null} 
   */
  static ParseJson(json) {
    return json ? new Variant(json.id, json.name) : null;
  }
}

export class VariantGroup {
  /**@type {string} */
  id;
  /**@type {string} */
  name;
  /**@type {Variant[]} */
  variants;

  /**
   * @param {string} id 
   * @param {string} name 
   * @param {Variant[]} variants 
   */
  constructor(id, name, variants) {
    this.id = id;
    this.name = name;
    this.variants = variants;
  }

  /**
 * @param {any} json
 * @returns {VariantGroup|null} 
 */
  static ParseJson(json) {
    if (json) {
      const variants = json.variants ? json.variants.map(v => Variant.ParseJson(v)) : [];

      return new VariantGroup(json.id, json.name, variants);
    }
    return null;
  }
}

export class Category {
  /**@type {string} */
  id;
  /**@type {string} */
  name;
  /**@type {boolean} */
  isActive;
  /**@type {ImageInfo} */
  image;
  /**@type {VariantGroup[]} */
  variantGroups;

  /**@type {FieldGroup[]} */
  fieldGroups;

  /**
   * @param {string} id 
   * @param {string} name 
   * @param {boolean} isActive 
   * @param {ImageInfo} image 
   * @param {VariantGroup[]} variantGroups 
   * @param {FieldGroup[]} fieldGroups 
   */
  constructor(id, name, isActive, image, variantGroups, fieldGroups) {
    this.id = id;
    this.name = name;
    this.isActive = isActive;
    this.image = image;
    this.variantGroups = variantGroups;
    this.fieldGroups = fieldGroups;
  }

  /**
   * @param {any} json
   * @returns {Categiry|null} 
   */
  static ParseJson(json) {
    if (json) {
      const image = ImageInfo.ParseJson(json.image);
      const variantGroups = json.variantGroups ? json.variantGroups.map(v => VariantGroup.ParseJson(v)) : [];
      const fieldGroups = json.fieldGroups ? json.fieldGroups.map(v => FieldGroup.ParseJson(v)) : [];
      return new Category(json.id, json.name, json.isActive, image, variantGroups, fieldGroups);
    }
    return null;
  }
}

export class CategoryPagedList {
  /**@type {Category[]} */
  items;
  /**@type {Pagination} */
  pagination;

  /**
   * @param {Category[]} items 
   * @param {Pagination} pagination 
   */
  constructor(items, pagination) {
    this.items = items;
    this.pagination = pagination;
  }

  /**
   * @param {any} json
   * @returns {CategoryPagedList|null} 
   */
  static ParseJson(json) {
    if (json) {
      const categories = json.items ? json.items.map(v => Category.ParseJson(v)) : [];
      const pagination = Pagination.ParseJson(json.pagination);
      return new CategoryPagedList(categories, pagination);
    }
    return null;
  }
}

export class CreateVariantGroupsResult extends BaseResult {
  /**@type {VariantGroup} */
  variantGroup;

  constructor(success, errorMessage, variantGroup) {
    super(success, errorMessage);
    this.variantGroup = variantGroup;
  }
}

export class CreateOrEditCategoryResult extends BaseResult {
  /**@type {Category} */
  category;

  constructor(success, errorMessage, category) {
    super(success, errorMessage);
    this.category = category;
  }
}
export class FieldValue{
  /**@type {string} */
  id;
  /**@type {string} */
  value;

  /**
   * @param {string} id
   * @param {string} value
   */
  constructor(id, value) {
    this.id = id;
    this.value = value;
  }

  /**
   * @param {any} json
   * @returns {Field|null}
   */
  static ParseJson(json) {
    return json ? new FieldValue(json.id, json.value) : null;
  }
}

export class Field {
  /**@type {string} */
  id;
  /**@type {string} */
  name;
  /**@type {FieldValue|null} */
  value;

  /**
   * @param {string} id 
   * @param {string} name
   * @param {FieldValue} value
   */
  constructor(id, name, value) {
    this.id = id;
    this.name = name;
    this.value = value;
  }

  /**
   * @param {any} json
   * @returns {Field|null} 
   */
  static ParseJson(json) {
    return json ? new Field(json.id, json.name, json.value) : null;
  }
}

export class FieldGroup {
  /**@type {string} */
  id;
  /**@type {string} */
  name;
  /**@type {Field[]} */
  fields;

  /**
   * @param {string} id 
   * @param {string} name 
   * @param {Field[]} fields 
   */
  constructor(id, name, fields) {
    this.id = id;
    this.name = name;
    this.fields = fields;
  }

/**
 * @param {any} json
 * @returns {FieldGroup|null} 
 */
static ParseJson(json) {
  if (json) {
    const fields = json.fields ? json.fields.map(v => Field.ParseJson(v)) : [];
    return new FieldGroup(json.id, json.name, fields);
  }
  return null;
}
}

export class CreateFieldGroupsResult extends BaseResult {
  /**@type {FieldGroup} */
  fieldGroup;

  constructor(success, errorMessage, fieldGroup) {
    super(success, errorMessage);
    this.fieldGroup = fieldGroup;
  }
}

export class Categories {
  /**
   * Список категорий
   * @param {number} page № страницы
   * @param {number} perPage Кол-во результатов на странице
   * @returns {Promise<CategoryPagedList>}
   */
  async getPagedList(page, perPage) {
    const result = await HttpClient.Get(`/v1/admin/categories?page=${page}&perPage=${perPage}`);
    if (result.data) {
      return CategoryPagedList.ParseJson(result.data);
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} name 
   * @param {boolean} isActive 
   * @returns {Promise<CreateOrEditCategoryResult>}
   */
  async createCategory(name, isActive) {
    const json = {name, isActive};

    const result = await HttpClient.PostJson(`/v1/admin/categories`, json);
    if (result.data) {
      return new CreateOrEditCategoryResult(true, null, Category.ParseJson(result.data));
    }
    return new BaseResult(false, result.error.message);
  }

  /**
 * @param {string} categoryId 
 * @param {string} name 
 * @param {boolean} isActive 
 * @returns {Promise<CreateOrEditCategoryResult>}
 */
  async editCategory(categoryId, name, isActive) {
    const json = {name, isActive};

    const result = await HttpClient.PutJson(`/v1/admin/categories/${categoryId}`, json);
    if (result.data) {
      return new CreateOrEditCategoryResult(true, null, Category.ParseJson(result.data));
    }
    return new BaseResult(false, result.error.message);
  }

  /**
* @param {string} categoryId 
* @returns {Promise<BaseResult>}
*/
  async deleteCategory(categoryId) {
    const result = await HttpClient.Delete(`/v1/admin/categories/${categoryId}`);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} categoryId 
   * @param {string} name 
   * @returns {Promise<CreateVariantGroupsResult>}
   */
  async createVariantGroups(categoryId, name){
    const json = {name};

    const result = await HttpClient.PostJson(`/v1/admin/categories/${categoryId}/variantGroups`, json);
    if (result.data) {
      return new CreateVariantGroupsResult(true, null, VariantGroup.ParseJson(result.data));
    }
    return new BaseResult(false, result.error.message);
  }
  
  /**
   * @param {string} categoryId 
   * @param {string} name 
   * @returns {Promise<CreateFieldGroupsResult>}
   */
  async createFieldGroups(categoryId, name){
    const json = {name};

    const result = await HttpClient.PostJson(`/v1/admin/categories/${categoryId}/fieldGroups`, json);
    if (result.data) {
      return new CreateFieldGroupsResult(true, null, FieldGroup.ParseJson(result.data));
    }
    return new BaseResult(false, result.error.message);
  }
}