<template>
  <div class="text-center">
    <v-dialog
            v-model="show"
            width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{header}}
        </v-card-title>

        <v-card-text>
          {{message}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
                  color="error"
                  text
                  @click="yesClick">
            Да
          </v-btn>
          <v-btn
                  color="primary"
                  text
                  @click="noClick">
            Нет
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: "ModalDialog",
    props: {show: Boolean, header: String, message: String, yesClicked: Function, noClicked: Function},
    methods: {
      yesClick() {
        if (this.yesClicked)
          this.yesClicked();
      },
      noClick() {
        if (this.noClicked)
          this.noClicked();
      }
    }
  }
</script>