import { Brands } from "./brands";
import { Categories } from "./category";
import { Colors } from "./colors";
import { AdvertConditions } from "./advertConditions";
import { Locations } from "./locations";
import { Products } from "./products";
import { Specs } from "./specs";
import { ComplaintReasons } from "./complaintReason";
import {Users} from "./users";
import {Adverts} from "./adverts";
import {Translations} from "./translations";
import {Feedbacks} from "@/api/admin/feedback";

export class Admin {
  #categories = new Categories();
  /**@type {Categories} */
  get categories() {
    return this.#categories;
  }

  #specs = new Specs();
  /**@type {Specs} */
  get specs() {
    return this.#specs;
  }

  #locations = new Locations();
  /**@type {Locations} */
  get locations() {
    return this.#locations;
  }

  #brands = new Brands();
  /**@type {Brands} */
  get brands() {
    return this.#brands;
  }

  #colors = new Colors();
  /**@type {Colors} */
  get colors() {
    return this.#colors;
  }

  #products = new Products();
  /**@type {Products} */
  get products() {
    return this.#products;
  }

  #advertConditions = new AdvertConditions();
  /**@type {AdvertConditions} */
  get advertConditions() {
    return this.#advertConditions;
  }

  #complaintReason = new ComplaintReasons();
  /**@type {ComplaintReasons} */
  get complaintReasons() {
    return this.#complaintReason;
  }

  #feedbacks = new Feedbacks();
  /**@type {Feedbacks} */
  get feedbacks() {
    return this.#feedbacks;
  }

  #users = new Users();
  /**@type {Users} */
  get users(){
    return this.#users;
  }

  #adverts = new Adverts();
  /**@type {Adverts} */
  get adverts(){
    return this.#adverts;
  }

  #translations = new Translations();
  /**@type {Translations} */
  get translations(){
    return this.#translations;
  }
}