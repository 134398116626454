<template>
  <v-app>
    <v-app-bar app fixed color="blue-grey lighten-3">
      <v-toolbar-title>Админка</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn text v-if="authorized" @click="logout">
        <span>Выход</span>
        <v-icon right>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-if="authorized"
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      app
      fixed
    >
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img :src="userPhoto"></v-img>
        </v-list-item-avatar>

        <v-list-item-title>{{ userName }}</v-list-item-title>

        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.link"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  computed: {
    authorized() {
      return this.$store.state.authorized;
    },
    userName() {
      return this.$store.state.userName;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
  },
  methods: {
    logout() {
      this.$store.commit("logout");
      this.$router.replace("/login");
    },
  },
  data: () => ({
    //https://iconify.design/icon-sets/mdi/
    drawer: true,
    items: [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        link: "/",
      },      {
        title: "Категории",
        icon: "mdi-card-bulleted-settings-outline",
        link: "/category",
      },
      {
        title: "Бренды",
        icon: "mdi-cellphone-information",
        link: "/brand",
      },           
      {
        title: "Цвет",
        icon: "mdi-format-color-fill",
        link: "/color",
      },                
      {
        title: "Продукты",
        icon: "mdi-cellphone-basic",
        link: "/product",
      },
      {
        title: "Регионы",
        icon: "mdi-map-legend",
        link: "/location",
      },
      {
        title: "Список состояний",
        icon: "mdi-state-machine",
        link: "/advertCondition",
      },
      {
        title: "Причины для жалоб",
        icon: "mdi-help-circle-outline",
        link: "/complaintReason",
      },
      {
        title: "Список жалоб",
        icon: "mdi-alert-octagon-outline",
        link: "/complaints",
      },
      {
        title: "Пользователи",
        icon: "mdi-badge-account-horizontal-outline",
        link: "/users",
      },
      {
        title: "Объявления",
        icon: "mdi-tablet-dashboard",
        link: "/adverts",
      },
      {
        title: "Переводы",
        icon: "mdi-ab-testing",
        link: "/translations",
      },
      {
        title: "Установка ОТП",
        icon: "mdi-form-textbox-password",
        link: "/setOtp",
      }
    ],
    mini: false,
  }),
};
</script>

<style scoped>
</style>
