<template>
  <v-container>
    <p v-if="items.length" class="text-h6">Обратная связь</p>
    <v-card v-for="item in items" :key="item.id" class="my-2" elevation="0">
      <v-card-text>
        <v-row>
          <v-col cols="5"><UserCard :user="item.user" /></v-col>
          <v-col>
            <div class="text-overline">
              <v-btn x-small fab outlined class="mr-1" color="success" @click="selectedId = item.id"><v-icon>mdi-check</v-icon></v-btn>
              {{ item.datetime }}
            </div>
            <p class="mt-2" style="white-space: break-spaces;">{{ item.message }}</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showIsReadDialog" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Прочитано
        </v-card-title>
        <v-card-text>
          <p v-if="error" class="error--text">{{ errorMessage }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="disabledSubmitButton" color="success" @click="setIsRead()">Прочитано</v-btn>
          <v-btn @click="cancel()">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from "@/api/api";
import UserCard from "@/components/user/UserCard";

export default {
  name: "FeedbacksList",
  components: {UserCard},
  data: () => ({
    items: [],
    selectedId: null,
    error: false,
    errorMessage: '',
    disabledSubmitButton: false,
  }),
  created: function () {
    this.fetchItems()
  },
  watch: {
  },
  computed: {
    showIsReadDialog() {
      return null !== this.selectedId
    }
  },
  methods: {
    async fetchItems() {
      this.items = await api.admin.feedbacks.getFeedbacks();
    },
    async setIsRead() {
      this.disabledSubmitButton = true
      const result = await api.admin.feedbacks.setIsRead(this.selectedId)
      if (result.success) {
        this.fetchItems()
        this.cancel()
      } else {
        this.error = true
        this.errorMessage = result.errorMessage
        this.disabledSubmitButton = false
      }
    },
    cancel() {
      this.selectedId = null
      this.disabledSubmitButton = false
      this.error = false
      this.errorMessage = ''
    },
  },
};
</script>