import {Pagination} from "../commonStucts";
import HttpClient, {BaseResult} from "../httpClient";
import {Brand} from "./brands";
import {Category, FieldGroup, VariantGroup} from "./category";
import {ColorInfo} from "./colors";

export class ProductImageInfo {
  /**@type {string} */
  id;
  /**@type {string} */
  imagePath;
  /**@type {string} */
  thumbPath;
  /**@type {ColorInfo[]} */
  colors;

  /**
   *
   * @param {string} id
   * @param {string} imagePath
   * @param {string} thumbPath
   * @param {ColorInfo[]} colors
   */
  constructor(id, imagePath, thumbPath, colors) {
    this.id = id;
    this.imagePath = imagePath;
    this.thumbPath = thumbPath;
    this.colors = colors;
  }

  /**
   * @param {any} json
   * @returns {ProductImageInfo|null}
   */
  static ParseJson(json) {
    return json ? new ProductImageInfo(json.id, json.imagePath, json.thumbPath, json.colors) : null;
  }
}

export class Product {
  /**@type {string} */
  id;
  /**@type {string} */
  name;
  /**@type {string} */
  releasedAt;
  /**@type {boolean} */
  isActive;
  /**@type {string} */
  brandText;
  /**@type {string} */
  categoryText;

  /**@type {Brand} */
  brand;
  /**@type {Category} */
  category;
  /**@type {ColorInfo[]} */
  colors;
  /**@type {ProductImageInfo[]} */
  images;
  /**@type {FieldGroup[]} */
  fieldGroups;
  /**@type {VariantGroup[]} */
  variantGroups;

  constructor(id, name, releasedAt, isActive, brand, category, colors, images, fieldGroups, variantGroups) {
    this.id = id;
    this.name = name;
    this.releasedAt = releasedAt;
    this.isActive = isActive;
    this.brand = brand;
    this.category = category;
    this.colors = colors;
    this.images = images;
    this.fieldGroups = fieldGroups;
    this.variantGroups = variantGroups;
    this.brandText = brand ? brand.name : "";
    this.categoryText = category ? category.name : "";
  }

  /**
   * @param {any} json
   * @returns {Product|null}
   */
  static ParseJson(json) {
    if (json) {

      const brand = json.brand ? Brand.ParseJson(json.brand) : null;
      const category = json.category ? Category.ParseJson(json.category) : null;
      const colors = json.colors ? json.colors.map(v => ColorInfo.ParseJson(v)) : [];
      const images = json.images ? json.images.map(v => ProductImageInfo.ParseJson(v)) : [];
      const fieldGroups = json.fieldGroups ? json.fieldGroups.map(v => FieldGroup.ParseJson(v)) : [];
      const variantGroups = json.variantGroups ? json.variantGroups.map(v => VariantGroup.ParseJson(v)) : [];
      return new Product(json.id, json.name, json.releasedAt, json.isActive, brand, category, colors, images, fieldGroups, variantGroups);
    }
    return null;
  }
}

export class ProductPagedList {
  /**@type {Product[]} */
  items;
  /**@type {Pagination} */
  pagination;

  /**
   * @param {Product[]} items
   * @param {Pagination} pagination
   */
  constructor(items, pagination) {
    this.items = items;
    this.pagination = pagination;
  }

  /**
   * @param {any} json
   * @returns {ProductPagedList|null}
   */
  static ParseJson(json) {
    if (json) {
      const items = json.items ? json.items.map(v => Product.ParseJson(v)) : [];
      const pagination = Pagination.ParseJson(json.pagination);
      return new ProductPagedList(items, pagination);
    }
    return null;
  }
}

export class IdValue {
  /**@type {string} */
  id;
  /**@type {string} */
  name;
}

export class AutocompleteCategory {
  /**@type {string} */
  id;
  /**@type {string} */
  name;
  /**@type {IdValue[]} */
  variants;
  /**@type {IdValue[]} */
  fields;
}

export class Autocomplete {
  /**@type {IdValue[]} */
  brands;
  /**@type {IdValue[]} */
  colors;
  /**@type {AutocompleteCategory[]} */
  categories;
}


export class Products {
  /**
   * @param {number} page № страницы
   * @param {number} perPage Кол-во результатов на странице
   * @param {string|null} sortField Поле для сортировки (releasedAt | name | isActive | brand | category)
   * @param {string|null} sortDir Направление сортировки (asc | desc)
   * @param {string|null} categoryId
   * @param {string|null} brandId
   * @param {string|null} searchText
   * @returns {Promise<ProductPagedList>}
   */
  async getPagedList(page, perPage, sortField, sortDir, categoryId, brandId, searchText) {
    const sort = sortField && sortDir ? `&sortField=${sortField}&sortDir=${sortDir}`: "";
    const category = categoryId ? `&categoryId=${categoryId}` : "";
    const brand = brandId ? `&brandId=${brandId}` : "";
    const search = searchText ? `&search=${searchText}` : "";

    const result = await HttpClient.Get(`/v1/admin/products?page=${page}&perPage=${perPage}${sort}${category}${brand}${search}`);
    if (result.data) {
      return ProductPagedList.ParseJson(result.data);
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @returns {Promise<Autocomplete>}
   */
  async getAutocomplete() {
    const result = await HttpClient.Get(`/v1/admin/extra/autocomplete`);
    if (result.data) {
      return result.data;
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} name
   * @param {string} releasedAt Дата релиза в формате YYYY-MM-DD
   * @param {string} brandId
   * @param {string} categoryId
   * @param {boolean} isActive
   * @returns {Promise<Product>}
   */
  async createProduct(name, releasedAt, brandId, categoryId, isActive) {
    const json = {name, releasedAt, brandId, categoryId, isActive};

    const result = await HttpClient.PostJson(`/v1/admin/products`, json);
    if (result.data) {
      result.data.success = true;
      return result.data;
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} name
   * @param {string} name
   * @param {string} releasedAt Дата релиза в формате YYYY-MM-DD
   * @param {string} brandId
   * @param {boolean} isActive
   * @returns {Promise<Product>}
   */
  async updateProduct(productId, name, releasedAt, brandId, isActive) {

    const json = {name, releasedAt, brandId, isActive};

    const result = await HttpClient.PutJson(`/v1/admin/products/${productId}`, json);
    if (result.data) {
      result.data.success = true;
      return result.data;
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} productId
   * @returns {Promise<BaseResult>}
   */
  async deleteProduct(productId) {
    const result = await HttpClient.Delete(`/v1/admin/products/${productId}`);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} productId
   * @param {string} colorId
   * @returns {Promise<BaseResult>}
   */
  async addProductColor(productId, colorId) {
    const result = await HttpClient.Post(`/v1/admin/products/${productId}/colors/${colorId}`);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }


  /**
   * @param {string} productId
   * @param {string} colorId
   * @returns {Promise<BaseResult>}
   */
  async deleteProductColor(productId, colorId) {
    const result = await HttpClient.Delete(`/v1/admin/products/${productId}/colors/${colorId}`);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }


  /**
   * @param {string} productId
   * @param {string} fieldId
   * @returns {Promise<BaseResult>}
   */
  async addProductField(productId, fieldId) {
    const result = await HttpClient.Post(`/v1/admin/products/${productId}/colors/${fieldId}`);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }


  /**
   * @param {string} productId
   * @param {string} variantId
   * @returns {Promise<BaseResult>}
   */
  async addProductVariant(productId, variantId) {
    const result = await HttpClient.Post(`/v1/admin/products/${productId}/variants/${variantId}`);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} productId
   * @param {string} variantId
   * @returns {Promise<BaseResult>}
   */
  async deleteProductVariant(productId, variantId) {
    const result = await HttpClient.Delete(`/v1/admin/products/${productId}/variants/${variantId}`);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} productId
   * @param {string} fieldId
   * @param {string} value
   * @returns {Promise<FieldValue>}
   */
  async addProductFieldValue(productId, fieldId, value) {
    const json = {value};
    const result = await HttpClient.PostJson(`/v1/admin/products/${productId}/fields/${fieldId}`, json);
    if (result.data) {
      result.data.success = true;
      return result.data;
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} fieldValueId
   * @param {string} value
   * @returns {Promise<FieldValue>}
   */
  async editProductFieldValue(fieldValueId, value) {
    const json = {value};
    const result = await HttpClient.PutJson(`/v1/admin/specs/fieldValues/${fieldValueId}`, json);
    if (result.data) {
      result.data.success = true;
      return result.data;
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} fieldValueId
   * @returns {Promise<BaseResult>}
   */
  async deleteProductFieldValue(fieldValueId) {
    const result = await HttpClient.Delete(`/v1/admin/specs/fieldValues/${fieldValueId}`);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} productId
   * @param {Blob} file
   * @returns {Promise<BaseResult>}
   */
  async addProductImage(productId, file){
    var data = new FormData()
    data.append('image', file);
    const result = await HttpClient.Post(`/v1/admin/products/${productId}/images`, data);
    if (result.data) {
      result.data.success = true;
      return result.data;
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} imageId
   * @returns {Promise<ImageInfo>}
   */
  async deleteProductImage(imageId){
    const result = await HttpClient.Delete(`/v1/admin/products/images/${imageId}`);
    if (result.data) {
      return new BaseResult(result.data.success, null);
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} imageId
   * @param {string} colorId
   * @returns {Promise<BaseResult>}
   */
  async addProductImageColor(imageId, colorId){
    const result = await HttpClient.Post(`/v1/admin/products/images/${imageId}/colors/${colorId}`);
    if (result.data) {
      result.data.success = true;
      return result.data;
    }
    return new BaseResult(false, result.error.message);
  }

  /**
   * @param {string} imageId
   * @param {string} colorId
   * @returns {Promise<BaseResult>}
   */
  async deleteProductImageColor(imageId, colorId){
    const result = await HttpClient.Delete(`/v1/admin/products/images/${imageId}/colors/${colorId}`);
    if (result.data) {
      result.data.success = true;
      return result.data;
    }
    return new BaseResult(false, result.error.message);
  }
}