<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <v-data-table
          item-key="id"
          :headers="advertConditionHeaders"
          :items="advertCondition"
          :options.sync="advertConditionOptions"
          :server-items-length="totalAdvertConditions"
          :loading="loading"
          :single-select="true"
          class="elevation-1"
          :footer-props="{'items-per-page-options': [10, 50, 100, 500]}"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Список состояний</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="advertConditionDialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Добавить
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="editedAdvertCondition.name"
                            label="Состояние"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="editedAdvertCondition.description"
                            label="Описание"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="advertConditionClose">
                      Отмена
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="advertConditionSave">
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="advertConditionDialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Удалить запись?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="advertConditionCloseDelete"
                      >Нет</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deleteAdvertConditionConfirm"
                      >Да</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editAdvertCondition(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteAdvertCondition(item)"> mdi-delete </v-icon>
          </template>
          <template v-slot:no-data> </template>
        </v-data-table>
      </v-col>
    </v-row>
    <ModalError :show="showError" :header="'Ошибка'" :message="errorMessage" :okClicked="()=>showError=false"/>
  </v-container>
</template>

<script>
import api from "../api/api";
import ModalError from "../components/common/ModalError";
export default {
  name: "AdvertConditionPage",
  components: {ModalError},
  data: () => ({
    selectedAdvertCondition: [],
    totalAdvertConditions: 0,
    advertCondition: [],
    loading: true,
    advertConditionOptions: {},
    advertConditionHeaders: [
      {
        text: "Состояние",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Описание",
        align: "start",
        sortable: false,
        value: "description",
      },
      { value: "actions", sortable: false, width:80 }
    ],
    editedAdvertCondition: {
      id: "",
      name: "",
      description: ""
    },
    defaultAdvertCondition: {
      name: "",
      description:""
    },
    advertConditionDialog: false,
    advertConditionDialogDelete: false,
    editedIndex: -1,
    showError: false,
    errorMessage: ""
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Добавить запись"
        : "Редактировать запись";
    },
  },
  watch: {
    advertConditionDialog(val) {
      val || this.advertConditionClose();
    },
    advertConditionDialogDelete(val) {
      val || this.advertConditionCloseDelete();
    },
    advertConditionOptions: {
      handler() {
        this.getAdvertConditions();
      },
      deep: true,
    },
  },
  methods: {
    async getAdvertConditions() {
      this.loading = true;
      const { page, itemsPerPage } = this.advertConditionOptions;
      const result = await api.admin.advertConditions.getPagedList(page, itemsPerPage);
      this.advertCondition = result.items;
      this.totalAdvertConditions = result.pagination.totalItems;
      this.loading = false;
    },

    editAdvertCondition(item) {
      this.editedIndex = this.advertCondition.indexOf(item);
      this.editedAdvertCondition = Object.assign({}, item);
      this.advertConditionDialog = true;
    },

    deleteAdvertCondition(item) {
      this.editedIndex = this.advertCondition.indexOf(item);
      this.editedAdvertCondition = Object.assign({}, item);
      this.advertConditionDialogDelete = true;
    },

    async deleteAdvertConditionConfirm() {
      const result = await api.admin.advertConditions.deleteAdvertCondition(
        this.editedAdvertCondition.id
      );
      if (result.success) {
        this.advertCondition.splice(this.editedIndex, 1);
      } else {
        this.errorMessage = result.errorMessage;
        this.showError = true;
      }
      this.advertConditionCloseDelete();
    },

    advertConditionClose() {
      this.advertConditionDialog = false;
      this.$nextTick(() => {
        this.editedAdvertCondition = Object.assign({}, this.defaultAdvertCondition);
        this.editedIndex = -1;
      });
    },

    advertConditionCloseDelete() {
      this.advertConditionDialogDelete = false;
      this.$nextTick(() => {
        this.editedAdvertCondition = Object.assign({}, this.defaultAdvertCondition);
        this.editedIndex = -1;
      });
    },

    async advertConditionSave() {
      if (this.editedIndex > -1) {
        const result = await api.admin.advertConditions.editAdvertCondition(
          this.editedAdvertCondition.id,
          this.editedAdvertCondition.name,
          this.editedAdvertCondition.description
        );
        if (result.success) {
          Object.assign(this.advertCondition[this.editedIndex], result.advertCondition);
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
          return;
        }
      } else {
        const result = await api.admin.advertConditions.createAdvertCondition(
          this.editedAdvertCondition.name,
          this.editedAdvertCondition.description
        );
        if (result.success) {
          this.advertCondition.push(result.advertCondition);
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
          return;
        }
      }
      this.advertConditionClose();
    },
  },
};
</script>