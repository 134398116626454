<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <ProductFilter :brands="brands" :categories="categories" :filter-changed="filterChanged"/>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col>
        <v-data-table
                item-key="id"
                v-model="selectedProducts"
                show-select
                :headers="productHeaders"
                :items="products"
                :options.sync="productOptions"
                :server-items-length="totalProducts"
                :loading="loading"
                :single-select="true"
                class="elevation-1"
                :footer-props="{'items-per-page-options': [10, 50, 100, 500]}"
        >
          <template v-slot:[`item.colorUse`]="{ item }">
            <span :class="item.colorUseOk ? 'green--text' : 'red--text'">{{item.colorUse}}</span>
          </template>
          <template v-slot:[`item.isActive`]="{ item }">
            <span :class="item.isActive ? 'green--text' : 'red--text'">{{item.isActive ? "Да" : "Нет"}}</span>
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ProductMainInfoEditor :selectedProduct="selectedProduct" :brands="brands" :categories="categories"
                               :selectedCategoryChanged="selectedCategory"
                               :selected-product-changed="selectedProductCahnged"
                               :added-product="addedProduct" :deleted-product="deletedProduct"
        />
      </v-col>
    </v-row>

    <v-row v-show="selectedProduct">
      <!-- Поля -->
      <v-col cols="12" md="4">
        <ProductFieldEditor :selectedProduct="selectedProduct" :fields="fields"/>
      </v-col>
      <!-- Варианты -->
      <v-col cols="12" md="4">
        <ProductVariantEditor :selectedProduct="selectedProduct" :variants="variants"/>
      </v-col>
      <!-- Цвет -->
      <v-col cols="12" md="4">
        <ProductColorEditor :selectedProduct="selectedProduct" :colors="colors"/>
      </v-col>

    </v-row>

    <v-row v-show="selectedProduct">
      <v-col cols="12">
        <ProductImagesEditor :selectedProduct="selectedProduct"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import {Product} from "../api/admin/products";
  import api from "../api/api";
  import ProductColorEditor from "../components/product/ProductColorEditor";
  import ProductVariantEditor from "../components/product/ProductVariantEditor";
  import ProductFieldEditor from "../components/product/ProductFieldEditor";
  import ProductMainInfoEditor from "../components/product/ProductMainInfoEditor";
  import ProductImagesEditor from "../components/product/ProductImagesEditor";
  import ProductFilter from "../components/product/ProductFilter";

  export default {
    name: "ProductPage",
    components: {
      ProductFilter,
      ProductImagesEditor,
      ProductMainInfoEditor,
      ProductFieldEditor,
      ProductVariantEditor,
      ProductColorEditor
    },
    data: () => ({
      selectedProducts: [],
      totalProducts: 0,
      products: [],
      loading: true,
      productOptions: {},
      productHeaders: [
        {
          text: "Название",
          align: "start",
          sortable: true,
          value: "name",
        },
        {text: "Бренд", value: "brandText", sortable: true},
        {text: "Категория", value: "categoryText", sortable: true},
        {text: "Дата релиза", value: "releasedAt", sortable: true, width: 140},
        {text: "Использование цвета", value: "colorUse", sortable: true},
        {text: "Активно", value: "isActive", sortable: true, width: 100},
      ],

      selectedProduct: null,
      editedIndex: -1,

      //Autocomplete
      brands: [],
      categories: [],
      colors: [],
      variants: [],
      fields: [],

      //search
      categoryId: null,
      brandId: null,
      searchText: null,
    }),
    created: function () {
      this.getAutocomplete();
    },
    watch: {
      productOptions: {
        handler() {
          this.getProducts();
        },
        deep: true,
      },

      selectedProducts(rows) {
        this.selectedProduct = rows && rows.length == 1 ? rows[0] : null;
      },
    },
    methods: {
      filterChanged(categoryId, brandId, searchText) {
        this.categoryId = categoryId;
        this.brandId = brandId;
        this.searchText = searchText;
        this.getProducts();
      },
      async getProducts() {
        this.loading = true;
        const {sortBy, sortDesc, page, itemsPerPage} = this.productOptions;
        const sortField = sortBy.length === 1 ? sortBy[0].replace("Text", "") : null;
        const sortDir = sortDesc.length === 1 && sortDesc[0] ? "asc" : "desc";
        const result = await api.admin.products.getPagedList(page, itemsPerPage, sortField, sortDir, this.categoryId, this.brandId, this.searchText);
        this.products = result.items;

        if (this.products) {
          for (const p of this.products) {
            let colorCount = p.colors ? p.colors.length : 0;
            const imgCount =p.images.length;
            let imgWithColor = 0;
            let foundZero = false;
            p.colorUse = colorCount + "";
            if (colorCount > 0) {
              p.colorUse += " - "
              for (const c of p.colors) {
                let counter = 0;
                for (const i of p.images){
                  if(i.colors.some(value => value.id === c.id))
                  {
                    counter++
                  }
                }
                if (counter === 0)
                  foundZero = true

                p.colorUse += counter+";";
              }
              for (const i of p.images){
                if(i.colors.length > 0)
                {
                  imgWithColor++
                }
              }
              p.colorUse += ` (${imgWithColor} из ${imgCount})`;
            }
            p.colorUseOk = colorCount > 0 && imgCount > 0 && imgWithColor == imgCount && !foundZero;
          }
        }

        this.selectedProducts = [];
        this.totalProducts = result.pagination.totalItems;
        this.loading = false;
      },
      async getAutocomplete() {
        const result = await api.admin.products.getAutocomplete();
        this.brands = result.brands;
        this.categories = result.categories;
        this.colors = result.colors;
      },
      selectedCategory(category) {
        this.variants = category
            ? category.variants
            : [];
        this.fields = category ? category.fields : [];
      },
      selectedProductCahnged(product) {
        product.brandText = product.brand.name;
        const index = this.products.findIndex((p) => p.id === product.id);
        Object.assign(this.products[index], product);
        this.selectedProduct = product;
      },
      addedProduct(product) {
        product.brandText = product.brand.name;
        product.categoryText = product.category.name;

        this.products.push(product);
        this.selectedProduct = product;
      },
      deletedProduct(productId) {
        const index = this.products.findIndex(
            (p) => p.id === productId
        );
        this.products.splice(index, 1);
        this.selectedProducts = [];
      }
    },
  };
</script>