import { ImageInfo } from './commonStucts';
import HttpClient, { BaseResult } from './httpClient';

export class UserProfile {
  /**@type {string} */
  id;  /* ID */
  /**@type {null|string } */
  email; /* Email */
  /**@type {null|string} */
  phone;  /* Номер телефона */
  /**@type {null|string} */
  name; /* Имя */
  /**@type {null|ImageInfo} */
  image;

  /**
   * 
   * @param {string} id 
   * @param {null|string} email 
   * @param {null|string} phone 
   * @param {null|string} name 
   * @param {null|ImageInfo} image 
   */
  constructor(id, email, phone, name, image) {
    this.id = id;
    this.email = email;
    this.phone = phone;
    this.image = image;
    this.name = name;
  }
}

export class UserProfileResult extends BaseResult {
  /**@type {UserProfile} */
  userProfile;

  constructor(success, errorMessage, userProfile) {
    super(success, errorMessage);
    this.userProfile = userProfile;
  }
}

export class User {
  /**
   * @returns {Promise<UserProfileResult>}
   */
  async getProfile() {
    const result = await HttpClient.Get('/v1/user/profile');
    if (result.data) {
      const img = result.data.image;
      const image = ImageInfo.ParseJson(img);

      const p = result.data;
      const profile = new UserProfile(p.id, p.email, p.phone, p.name, image);
      return new UserProfileResult(true, null, profile);
    }
    return new BaseResult(false, result.error.message);
  }
}