<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <v-data-table
          item-key="id"
          :headers="brandHeaders"
          :items="brands"
          :options.sync="brandOptions"
          :server-items-length="totalBrands"
          :loading="loading"
          :single-select="true"
          class="elevation-1"
          :footer-props="{'items-per-page-options': [10, 50, 100, 500]}"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Бренды</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="brandDialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Добавить
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="9">
                          <v-text-field
                            v-model="editedBrand.name"
                            label="Бренд"
                          ></v-text-field>
                        </v-col>
                         <v-col cols="3">
                          <v-checkbox
                            v-model="editedBrand.isActive"
                            label="Активно"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="brandClose">
                      Отмена
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="brandSave">
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="brandDialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Удалить запись?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="brandCloseDelete"
                      >Нет</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deleteBrandConfirm"
                      >Да</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <!--Image-->
          <template v-slot:[`item.image`]="{ item }">
            <img v-if="item.image" :src="item.image ? item.image.thumbUrl : ''" width="80" height="80">
          </template>
          <template v-slot:[`item.isActive`]="{ item }">
            <span :class="item.isActive ? 'green--text' : 'red--text'">{{item.isActive ? "Да" : "Нет"}}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editBrand(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteBrand(item)"> mdi-delete </v-icon>
          </template>
          <template v-slot:no-data> </template>
        </v-data-table>
      </v-col>
    </v-row>
    <ModalError :show="showError" header="Ошибка" :message="errorMessage" :okClicked="()=>showError=false"/>

  </v-container>
</template>

<script>
import api from "../api/api";
import ModalError from "../components/common/ModalError";
export default {
  name: "BrandPage",
  components: {ModalError},
  data: () => ({
    selectedBrands: [],
    totalBrands: 0,
    brands: [],
    loading: true,
    brandOptions: {},
    brandHeaders: [
      {
        text: "Фото",
        align: "start",
        sortable: false,
        value: "image",
        width: 80
      },
      {
        text: "Бренд",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Активно", value: "isActive", sortable: false, width: 70 },
      { value: "actions", sortable: false, width: 80 },
    ],
    editedBrand: {
      id: "",
      name: "",
      isActive: false
    },
    defaultBrand: {
      name: "",
      isActive: true
    },
    brandDialog: false,
    brandDialogDelete: false,
    editedIndex: -1,
    showError: false,
    errorMessage: ""
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Добавить запись"
        : "Редактировать запись";
    },
  },
  watch: {
    brandDialog(val) {
      val || this.brandClose();
    },
    brandDialogDelete(val) {
      val || this.brandCloseDelete();
    },
    brandOptions: {
      handler() {
        this.getBrands();
      },
      deep: true,
    },
  },
  methods: {
    async getBrands() {
      this.loading = true;
      const { page, itemsPerPage } = this.brandOptions;
      const result = await api.admin.brands.getPagedList(page, itemsPerPage);
      this.brands = result.items;
      console.log(this.brands);
      this.totalBrands = result.pagination.totalItems;
      this.loading = false;
    },

    editBrand(item) {
      this.editedIndex = this.brands.indexOf(item);
      this.editedBrand = Object.assign({}, item);
      this.brandDialog = true;
    },

    deleteBrand(item) {
      this.editedIndex = this.brands.indexOf(item);
      this.editedBrand = Object.assign({}, item);
      this.brandDialogDelete = true;
    },

    async deleteBrandConfirm() {
      const result = await api.admin.brands.deleteBrand(
        this.editedBrand.id
      );
      if (result.success) {
        this.brands.splice(this.editedIndex, 1);
      } else {
        this.errorMessage = result.errorMessage;
        this.showError = true;
      }
      this.brandCloseDelete();
    },

    brandClose() {
      this.brandDialog = false;
      this.$nextTick(() => {
        this.editedBrand = Object.assign({}, this.defaultBrand);
        this.editedIndex = -1;
      });
    },

    brandCloseDelete() {
      this.brandDialogDelete = false;
      this.$nextTick(() => {
        this.editedBrand = Object.assign({}, this.defaultBrand);
        this.editedIndex = -1;
      });
    },

    async brandSave() {
      if (this.editedIndex > -1) {
        const result = await api.admin.brands.editBrand(
          this.editedBrand.id,
          this.editedBrand.name,
          this.editedBrand.isActive
        );
        if (result.success) {
          Object.assign(this.brands[this.editedIndex], result.brand);
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
          return;
        }
      } else {
        const result = await api.admin.brands.createBrand(
          this.editedBrand.name,
          this.editedBrand.isActive
        );
        if (result.success) {
          this.brands.push(result.brand);
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
          return;
        }
      }
      this.brandClose();
    },
  },
};
</script>