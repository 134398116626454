<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <AdvertFilter :brands="brands" :categories="categories" :filter-changed="filterChanged"/>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col>
        <v-data-table
                item-key="id"
                :headers="advertsHeaders"
                :items="adverts"
                :options.sync="advertsOptions"
                :server-items-length="totalAdverts"
                :loading="loading"
                :single-select="true"
                class="elevation-1"
                :footer-props="{'items-per-page-options': [10, 50, 100, 500]}"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Объявления</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <!--Product-->
          <template v-slot:[`item.product`]="{ item }">
            <span><router-link :to="'/adverts/' + item.id">{{item.product.brand.name}} {{item.product.name}}</router-link></span>
          </template>
          <!--Phone-->
          <template v-slot:[`item.user`]="{ item }">
            <span>{{item.user.phone}}</span>
          </template>
          <!--Description-->
          <template v-slot:[`item.description`]="{ item }">
            <v-btn v-if="item.description" @click="showDescription(item)" text color="primary"
                   style="padding: 0; margin: 0">
              Есть
            </v-btn>
            <v-btn v-else text color="primary" disabled style="padding: 0; margin: 0">
              Нет
            </v-btn>
          </template>
          <!--Images-->
          <template v-slot:[`item.images`]="{ item }">
            <v-btn v-if="item.images && item.images.length > 0" @click="showImages(item)" text color="primary"
                   style="padding: 0; margin: 0">
              Есть
            </v-btn>
            <v-btn v-else text color="primary" disabled style="padding: 0; margin: 0">
              Нет
            </v-btn>
          </template>
          <!--Ban-->
          <template v-slot:[`item.isActive`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon :color="item.isApproved ? 'red' : 'blue'" v-bind="attrs" v-on="on"
                       @click="banAdvertClick(item)">
                  <v-icon>{{item.isApproved ? "mdi-cancel" : "mdi-check"}}</v-icon>
                </v-btn>
              </template>
              <span>{{item.isApproved ? "Забанить объявление" : "Разбанить объявление"}}</span>
              <p v-show="item.isApproved === false">{{item.banReason}}</p>
            </v-tooltip>
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-col>
    </v-row>
    <ModalError :show="showError" header="Ошибка" :message="errorMessage" :okClicked="()=>showError=false"/>
    <AdvertBanDialog :show="showBanDialog" :header="'Блокировка объявления'"
                     message="Вы действиетльно хотите забанить объявление?"
                     :yesClicked="banAdvertConfirm" :noClicked="()=>showBanDialog = false"/>

    <ModalDialog :show="showUnbanDialog" :header="'Блокировка объявления'"
                 message="Вы действиетльно хотите разбанить объявление?"
                 :yesClicked="unbanAdvertConfirm" :noClicked="()=>showUnbanDialog = false"/>


    <AdvertDescriptionDialog :show="showDescriptionDialog" :message="currentAdvertDescription"
                             :okClicked="()=>showDescriptionDialog=false"/>
    <AdvertImagesDialog :show="showImagesDialog" :images="currentAdvertImages"
                        :okClicked="()=>showImagesDialog=false"/>
  </v-container>
</template>

<script>
  import api from "../api/api";
  import ModalError from "../components/common/ModalError";
  import ModalDialog from "../components/common/ModalDialog";
  import AdvertDescriptionDialog from "../components/adverts/AdvertDescriptionDialog";
  import AdvertImagesDialog from "../components/adverts/AdvertImagesDialog";
  import AdvertBanDialog from "../components/adverts/AdvertBanDialog";
  import AdvertFilter from "../components/adverts/AdvertFilter";

  export default {
    name: "AdvertsPage",
    components: {AdvertFilter, AdvertBanDialog, AdvertImagesDialog, AdvertDescriptionDialog, ModalDialog, ModalError},
    data: () => ({
      selectedAdverts: [],
      totalAdverts: 0,
      adverts: [],
      loading: true,
      advertsOptions: {},
      advertsHeaders: [
        {
          text: "Продукт",
          align: "start",
          sortable: false,
          value: "product"
        },
        {
          text: "Телефон",
          align: "start",
          sortable: false,
          value: "user",
          width: 80
        },
        {
          text: "Цена",
          align: "start",
          sortable: true,
          value: "price",
          width: 80
        },
        {
          text: "Дата создания",
          align: "start",
          sortable: true,
          value: "createdAt",
          width: 160
        },
        {
          text: "Описание",
          align: "start",
          sortable: false,
          value: "description",
          width: 50
        },
        {
          text: "Фото загруженные пользователем",
          align: "start",
          sortable: false,
          value: "images",
          width: 60
        },
        {
          text: "",
          width: 40,
          align: "start",
          sortable: false,
          value: "isActive",
        }
      ],
      showError: false,
      errorMessage: "",
      showBanDialog: false,
      showUnbanDialog: false,
      banAdvert: null,
      showDescriptionDialog: false,
      currentAdvertDescription: "",
      showImagesDialog: false,
      currentAdvertImages: [],

      //Autocomplete
      brands: [],
      categories: [],

      //search
      categoryId: null,
      brandId: null,
      searchText: null,
      bannedAndEditedParam: false
    }),
    created: function () {
      this.getAutocomplete();
    },
    watch: {
      advertsOptions: {
        handler() {
          this.getAdverts();
        },
        deep: true,
      },
    },
    methods: {
      filterChanged(categoryId, brandId, searchText, bannedAndEditedParam) {
        this.categoryId = categoryId;
        this.brandId = brandId;
        this.searchText = searchText;
        this.bannedAndEditedParam = bannedAndEditedParam;
        this.getAdverts();
      },
      async getAutocomplete() {
        const result = await api.admin.products.getAutocomplete();
        this.brands = result.brands;
        this.categories = result.categories;
      },
      async getAdverts() {
        this.loading = true;
        const {sortBy, sortDesc, page, itemsPerPage} = this.advertsOptions;
        const sortField = sortBy.length === 1 ? sortBy[0] : null;
        const sortDir = sortDesc.length === 1 && sortDesc[0] ? "asc" : "desc";
        const result = await api.admin.adverts.getPagedList(page, itemsPerPage, sortField, sortDir, this.categoryId, this.brandId, this.searchText, this.bannedAndEditedParam);
        this.adverts = result.items;
        this.totalAdverts = result.pagination.totalItems;
        this.loading = false;
      },
      banAdvertClick(advert) {
        this.banAdvert = advert;
        if (advert.isApproved) {
          this.showBanDialog = true;
        } else {
          this.showUnbanDialog = true;
        }
      },
      async banAdvertConfirm(reason) {
        this.showBanDialog = false;
        const result = await api.admin.adverts.banAdvert(this.banAdvert.id, reason)
        if (result.success) {
          this.banAdvert.isApproved = false;
          this.banAdvert.banReason = reason;
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
      },
      async unbanAdvertConfirm() {
        this.showUnbanDialog = false;
        const result = await api.admin.adverts.unbanAdvert(this.banAdvert.id)
        if (result.success) {
          this.banAdvert.isApproved = true;
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
      },
      showDescription(advert) {
        this.currentAdvertDescription = advert.description;
        this.showDescriptionDialog = true;
      },
      showImages(advert) {
        console.log(advert.images);
        this.currentAdvertImages = advert.images;
        this.showImagesDialog = true;
      }

    },
  };
</script>