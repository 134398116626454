<template>
  <v-card elevation="4" shaped class="mx-auto mt-5" max-width="400">
    <v-card-text>
      <p class="display-1 text--primary text-center">Авторизация</p>
    </v-card-text>

    <v-card-text class="text--primary py-0">
      <v-text-field
        v-if="phoneHandler"
        label="Телефон"
        required
        v-model="phone"
      ></v-text-field>
      <v-text-field v-else label="OTP" required v-model="otp"></v-text-field>
    </v-card-text>

    <div class="text-center pb-3">
      <v-btn
        v-if="phoneHandler"
        elevation="2"
        rounded
        class="px-5"
        color="primary"
        @click="sendOTP"
        >Продолжить</v-btn
      >
      <v-btn
        v-else
        elevation="2"
        rounded
        class="px-5"
        color="primary"
        @click="getToken"
        >Войти</v-btn
      >
    </div>

    <div v-if="errorMessage != null" class="text-center">
      <span class="red--text"> {{ errorMessage }}</span>
    </div>

  </v-card>
</template>

<script>
import api from "./../api/api";

export default {
  name: "LoginPage",
  methods: {
    async sendOTP() {
      const result = await api.security.phone.sendOTP(this.phone);

      if (result.success) {
        this.phoneHandler = false;
      } else {
        this.errorMessage = result.errorMessage;
      }
    },
    async getToken() {
      const result = await api.security.phone.getToken(
        this.phone,
        this.otp,
        "device id"
      );

      if (result.success) {  
        this.$store.commit("authorization");
        this.$router.replace("/");
      } else {
        this.errorMessage = result.errorMessage;
      }
    },
  },
  data: () => ({
    phone: "",
    otp: "",
    errorMessage: null,
    phoneHandler: true,
  }),
};
</script>