import HttpClient, { BaseResult } from "../httpClient";

export class Feedbacks {

    async getFeedbacks() {
        const result = await HttpClient.Get(`/v1/admin/feedbacks`);
        if (result.data) {
            result.success = true;
            return result.data;
        }
        return new BaseResult(false, result.error.message);
    }

    async setIsRead(feedbackId) {
        const result = await HttpClient.Post(`/v1/admin/feedbacks/${feedbackId}`);
        if (result.data) {
            return new BaseResult(result.data.success, '');
        }
        return new BaseResult(false, result.error.message);
    }
}