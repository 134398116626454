<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <v-data-table
                item-key="id"
                :headers="usersHeaders"
                :items="users"
                :options.sync="usersOptions"
                :server-items-length="totalUsers"
                :loading="loading"
                :single-select="true"
                class="elevation-1"
                :footer-props="{'items-per-page-options': [10, 50, 100, 500]}"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Пользователи</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <!--Image-->
          <template v-slot:[`item.image`]="{ item }">
            <img v-if="item.image" :src="item.image ? item.image.thumbUrl : ''" width="80" height="80">
          </template>
          <!--Ban-->
          <template v-slot:[`item.isActive`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon :color="item.isActive ? 'red' : 'blue'" v-bind="attrs" v-on="on"
                       @click="banUserClick(item)">
                  <v-icon>{{item.isActive ? "mdi-account-cancel-outline " : "mdi-account-check-outline"}}</v-icon>
                </v-btn>
              </template>
              <span>{{item.isActive ? "Забанить пользователя" : "Разбанить пользователя"}}</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-col>
    </v-row>
    <ModalError :show="showError" :header="'Ошибка'" :message="errorMessage" :okClicked="()=>showError=false"/>
    <ModalDialog :show="showBanDialog" :header="'Блокировка пользователя'"
                 :message="'Вы действиетльно хотите забанить полльзователя?'"
                 :yesClicked="banUserConfirm" :noClicked="()=>showBanDialog = false"/>
    <ModalDialog :show="showUnbanDialog" :header="'Блокировка пользователя'"
                 :message="'Вы действиетльно хотите разбанить полльзователя?'"
                 :yesClicked="unbanUserConfirm" :noClicked="()=>showUnbanDialog = false"/>
  </v-container>
</template>

<script>
  import api from "../api/api";
  import ModalError from "../components/common/ModalError";
  import ModalDialog from "../components/common/ModalDialog";

  export default {
    name: "UsersPage",
    components: {ModalDialog, ModalError},
    data: () => ({
      selectedUsers: [],
      totalUsers: 0,
      users: [],
      loading: true,
      usersOptions: {},
      usersHeaders: [
        {
          text: "Фото",
          align: "start",
          sortable: false,
          value: "image",
          width: 80
        },
        {
          text: "Телефон",
          align: "start",
          sortable: false,
          value: "phone",
          width: 100
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: "Дата регистрации",
          align: "start",
          sortable: false,
          value: "createdAt",
          width: 160
        },
        {
          text: "Имя",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Кол-во объявлений",
          align: "start",
          sortable: false,
          value: "totalAdverts",
          width: 80
        },
        {
          text: "",
          width: 40,
          align: "start",
          sortable: false,
          value: "isActive",
        }
      ],
      showError: false,
      errorMessage: "",
      showBanDialog: false,
      showUnbanDialog: false,
      banUser: null
    }),
    watch: {
      usersOptions: {
        handler() {
          this.getUsers();
        },
        deep: true,
      },
    },
    methods: {
      async getUsers() {
        this.loading = true;
        const {page, itemsPerPage} = this.usersOptions;
        const result = await api.admin.users.getPagedList(page, itemsPerPage);
        this.users = result.items;
        this.totalUsers = result.pagination.totalItems;
        this.loading = false;
      },
      banUserClick(user) {
        this.banUser = user;
        if (user.isActive) {
          this.showBanDialog = true;
        } else {
          this.showUnbanDialog = true;
        }
      },
      async banUserConfirm() {
        this.showBanDialog = false;
        const result = await api.admin.users.banUser(this.banUser.id)
        if (result.success) {
          this.banUser.isActive = false;
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
      },
      async unbanUserConfirm() {
        this.showUnbanDialog = false;
        const result = await api.admin.users.unbanUser(this.banUser.id)
        if (result.success) {
          this.banUser.isActive = true;
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
        }
      }

    },
  };
</script>