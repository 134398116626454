<template>
  <v-dialog v-model="show" width="500" persistent>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Забанить
      </v-card-title>
      <v-card-text>
        <v-textarea v-model="banReason" :error="error" :error-messages="errorMessage" outlined label="Причина бана" class="mt-4"></v-textarea>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="disabledSubmitButton" color="error" @click="ban()">Забанить</v-btn>
        <v-btn @click="cancel()">Отмена</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api/api";

export default {
  name: "AdvertBan",
  props: {
    advertId: String,
    successCallback:  {
      type: Function,
      required: true,
    },
    cancelCallback: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    disabledSubmitButton: true,
    banReason: '',
    error: false,
    errorMessage: '',
  }),
  watch: {
    advertId() {
      this.disabledSubmitButton = true
      this.banReason = ''
      this.error = false
      this.errorMessage = ''
    },
    banReason() {
      this.disabledSubmitButton = this.banReason.length === 0
    },
  },
  computed: {
    show() {
      return null !== this.advertId
    }
  },
  methods: {
    async ban() {
      this.disabledSubmitButton = true
      const result = await api.admin.adverts.banAdvert(this.advertId, this.banReason)
      if (result.success) {
        this.successCallback()
      } else {
        this.error = true
        this.errorMessage = result.errorMessage
        this.disabledSubmitButton = false
      }
    },
    cancel() {
      this.cancelCallback()
    },
  },
}
</script>

<style scoped>

</style>