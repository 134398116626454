<template>
  <v-card outlined>
    <v-form>
      <v-container>
        <v-row>
          <v-col class="py-0">
            <p class="text-h5 py-0 my-0">Поиск объявлений</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
                    v-model="selectedBrand"
                    :items="brands"
                    item-text="name"
                    item-value="id"
                    label="Бренд"
                    clearable
                    return-object
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
                    v-model="selectedCategory"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    label="Категория"
                    clearable
                    return-object
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field
                    v-model="searchText"
                    label="Текстовый поиск"
                    clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-checkbox v-model="bannedAndEditedParam">
              <template v-slot:label>
                <div>
                  Показывать только отредактированные после бана
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
  export default {
    name: "AdvertFilter",
    props: {
      brands: Array,
      categories: Array,
      filterChanged: Function
    },
    data() {
      return {
        searchText: "",
        bannedAndEditedParam: false,
        productIsActive: false,
        selectedCategory: null,
        selectedCategoryText: "",
        selectedBrand: null,
      }
    },
    watch: {
      selectedCategory() {
        this.invokeFilter();
      },
      selectedBrand() {
        this.invokeFilter();
      },
      searchText() {
        this.invokeFilter();
      },
      bannedAndEditedParam() {
        this.invokeFilter();
      }
    },
    methods: {
      invokeFilter() {
        const categoryId = this.selectedCategory ? this.selectedCategory.id : null;
        const brandId = this.selectedBrand ? this.selectedBrand.id : null;
        this.filterChanged(categoryId, brandId, this.searchText, this.bannedAndEditedParam);
      }
    }
  }
</script>

<style scoped>

</style>