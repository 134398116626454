import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './plugins/store'
import router from './plugins/router'


Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  beforeCreate() {
    this.$store.commit('initialiseStore');},
  router,
  render: h => h(App)
}).$mount('#app')