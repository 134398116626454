<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <v-data-table
          item-key="id"
          :headers="colorHeaders"
          :items="colors"
          :options.sync="colorOptions"
          :server-items-length="totalColors"
          :loading="loading"
          :single-select="true"
          class="elevation-1"
          :footer-props="{'items-per-page-options': [10, 50, 100, 500]}"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Цвет</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="colorDialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Добавить
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="editedColor.name"
                            label="Цвет"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="colorClose">
                      Отмена
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="colorSave">
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="colorDialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Удалить запись?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="colorCloseDelete"
                      >Нет</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deleteColorConfirm"
                      >Да</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editColor(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteColor(item)"> mdi-delete </v-icon>
          </template>
          <template v-slot:no-data> </template>
        </v-data-table>
      </v-col>
    </v-row>
    <ModalError :show="showError" header="Ошибка" :message="errorMessage" :okClicked="()=>showError=false"/>

  </v-container>
</template>

<script>
import api from "../api/api";
import ModalError from "../components/common/ModalError";
export default {
  name: "ColorPage",
  components: {ModalError},
  data: () => ({
    selectedColors: [],
    totalColors: 0,
    colors: [],
    loading: true,
    colorOptions: {},
    colorHeaders: [
      {
        text: "Цвет",
        align: "start",
        sortable: false,
        value: "name",
      },
      { value: "actions", sortable: false, width: 80 },
    ],
    editedColor: {
      id: "",
      name: "",
    },
    defaultColor: {
      name: "",
    },
    colorDialog: false,
    colorDialogDelete: false,
    editedIndex: -1,
    showError: false,
    errorMessage: ""
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Добавить запись"
        : "Редактировать запись";
    },
  },
  watch: {
    colorDialog(val) {
      val || this.colorClose();
    },
    colorDialogDelete(val) {
      val || this.colorCloseDelete();
    },
    colorOptions: {
      handler() {
        this.getColors();
      },
      deep: true,
    },
  },
  methods: {
    async getColors() {
      this.loading = true;
      const { page, itemsPerPage } = this.colorOptions;
      const result = await api.admin.colors.getPagedList(page, itemsPerPage);
      this.colors = result.items;
      this.totalColors = result.pagination.totalItems;
      this.loading = false;
    },

    editColor(item) {
      this.editedIndex = this.colors.indexOf(item);
      this.editedColor = Object.assign({}, item);
      this.colorDialog = true;
    },

    deleteColor(item) {
      this.editedIndex = this.colors.indexOf(item);
      this.editedColor = Object.assign({}, item);
      this.colorDialogDelete = true;
    },

    async deleteColorConfirm() {
      const result = await api.admin.colors.deleteColor(
        this.editedColor.id
      );
      if (result.success) {
        this.colors.splice(this.editedIndex, 1);
      } else {
        this.errorMessage = result.errorMessage;
        this.showError = true;
      }
      this.colorCloseDelete();
    },

    colorClose() {
      this.colorDialog = false;
      this.$nextTick(() => {
        this.editedColor = Object.assign({}, this.defaultColor);
        this.editedIndex = -1;
      });
    },

    colorCloseDelete() {
      this.colorDialogDelete = false;
      this.$nextTick(() => {
        this.editedColor = Object.assign({}, this.defaultColor);
        this.editedIndex = -1;
      });
    },

    async colorSave() {
      if (this.editedIndex > -1) {
        const result = await api.admin.colors.editColor(
          this.editedColor.id,
          this.editedColor.name
        );
        if (result.success) {
          Object.assign(this.colors[this.editedIndex], result.color);
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
          return;
        }
      } else {
        const result = await api.admin.colors.createColor(
          this.editedColor.name
        );
        if (result.success) {
          this.colors.push(result.color);
        } else {
          this.errorMessage = result.errorMessage;
          this.showError = true;
          return;
        }
      }
      this.colorClose();
    },
  },
};
</script>